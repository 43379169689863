import { SVGAttributes } from "react";

export function LogoWithBg(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      {...props}
    >
      <circle cx="10.5" cy="10" r="10" fill="white" />
      <path
        d="M10.5 17C6.64049 17 3.5 13.9682 3.5 10.2423C3.5 8.69615 4.04622 7.20936 5.04705 6.00918L3.83979 5.42476L4.02071 4.47761L6.11166 4.98142C6.26822 4.85938 6.4213 4.74854 6.57555 4.64778C6.72283 4.5515 6.89447 4.5 7.0719 4.5C7.28297 4.5 7.48592 4.57165 7.64596 4.69704C8.53082 4.2884 9.50961 4.07456 10.4919 4.07456C10.6647 4.07456 10.8444 4.0824 11.0393 4.09807C11.3234 3.95477 11.604 3.86296 11.8453 3.80363C11.7954 3.40394 11.8 2.90013 11.9752 2.38401L12.083 2.06493L13.2856 2.44447C13.412 2.34147 13.55 2.24742 13.7008 2.1657L14.0035 2L14.6378 3.07703L14.3352 3.24272C14.305 3.25952 14.276 3.27631 14.2482 3.29422C14.4569 3.25392 14.6703 3.23377 14.8837 3.23377C15.024 3.23377 15.1667 3.24272 15.3093 3.25952L15.6549 3.30094L15.496 4.53023L15.1504 4.4888C15.0611 4.47873 14.9718 4.47313 14.8849 4.47313C14.6599 4.47313 14.4592 4.50896 14.2841 4.56158C14.8443 4.91088 15.3569 5.33632 15.7871 5.81549C16.8912 7.04366 17.5 8.61554 17.5 10.2445C17.5 13.9682 14.3607 17 10.5 17Z"
        fill="#D1D3D4"
      />
      <path
        d="M10.4919 4.4093C8.45314 4.4093 6.64399 5.34974 5.4982 6.80406H15.4868C14.3387 5.34974 12.5307 4.4093 10.4919 4.4093Z"
        fill="#FAD1D1"
      />
      <path
        d="M15.4856 6.80408H5.49701C5.39496 6.93395 5.29638 7.06606 5.20477 7.20376C4.8986 7.66391 4.65739 8.16659 4.48807 8.70063H16.4945C16.3252 8.16659 16.084 7.66391 15.7778 7.20376C15.6862 7.06606 15.5876 6.93395 15.4856 6.80408Z"
        fill="#FFB3D2"
      />
      <path
        d="M4.48808 8.70063C4.31064 9.26042 4.21439 9.85267 4.21439 10.4684C4.21439 10.5121 4.21671 10.5546 4.21786 10.5972H16.7659C16.7671 10.5546 16.7694 10.511 16.7694 10.4684C16.7694 9.85267 16.6731 9.2593 16.4957 8.70063H4.48808Z"
        fill="#FD77AB"
      />
      <path
        d="M4.2179 10.5972C4.23297 11.2633 4.35706 11.9026 4.5774 12.4982H16.4064C16.6256 11.9015 16.7509 11.2622 16.7659 10.5972H4.2179Z"
        fill="#18BCBB"
      />
      <path
        d="M10.4919 16.5275C12.4437 16.5275 14.1821 15.6632 15.3325 14.3141H5.65125C6.80168 15.6643 8.54008 16.5275 10.4919 16.5275Z"
        fill="#89E6E7"
      />
      <path
        d="M16.4052 12.4993H4.5762C4.75944 12.9975 5.01225 13.4622 5.3161 13.8898C5.42047 14.0365 5.53296 14.1764 5.65009 14.3142H15.3313C15.4485 14.1776 15.561 14.0365 15.6653 13.8898C15.9703 13.4622 16.222 12.9964 16.4052 12.4993Z"
        fill="#4AD8D4"
      />
      <path
        d="M10.5 16.663C6.83186 16.663 3.84793 13.7824 3.84793 10.2412C3.84793 8.10053 4.95313 6.11218 6.76924 4.9232C7.03829 4.74742 7.40824 4.84147 7.56596 5.11576C5.32424 6.31482 4.06828 8.7678 4.531 11.2779C5.03315 14.0051 7.51609 16.0416 10.3852 16.0931C13.7808 16.1536 16.563 13.5047 16.563 10.24C16.563 8.82937 16.0365 7.46798 15.0797 6.40439C14.4813 5.73824 13.7228 5.20421 12.8867 4.85826L13.1186 4.33654C14.0348 4.71607 14.8675 5.30273 15.5239 6.03269C16.5734 7.19928 17.1509 8.69391 17.1509 10.2412C17.1521 13.7824 14.1682 16.663 10.5 16.663Z"
        fill="#1E2628"
      />
      <path
        d="M16.7346 10.5972H4.26541V11.1648H16.7346V10.5972Z"
        fill="#1E2628"
      />
      <path
        d="M9.40641 12.5172H4.72119V13.0849H9.40641V12.5172Z"
        fill="#1E2628"
      />
      <path
        d="M15.0437 14.3377H10.1034V14.9053H15.0437V14.3377Z"
        fill="#1E2628"
      />
      <path
        d="M14.5241 12.5172H11.6028V13.0849H14.5241V12.5172Z"
        fill="#1E2628"
      />
      <g opacity="0.15">
        <path
          d="M10.5 6.23421C9.39246 6.23421 8.49136 7.10411 8.49136 8.1733C8.49136 9.24249 9.39246 10.1124 10.5 10.1124C11.6075 10.1124 12.5086 9.24249 12.5086 8.1733C12.5086 7.10411 11.6075 6.23421 10.5 6.23421Z"
          fill="#1E2628"
        />
      </g>
      <path
        d="M16.7357 8.67487H13.8144V9.24249H16.7357V8.67487Z"
        fill="#1E2628"
      />
      <path
        d="M7.78046 8.95812H4.17725V9.52574H7.78046V8.95812Z"
        fill="#1E2628"
      />
      <path
        d="M10.0257 5.71137H8.0275V6.27899H10.0257V5.71137Z"
        fill="#1E2628"
      />
      <path
        d="M6.42479 5.40125L4.29557 4.88849L4.2283 5.23668L5.64895 5.92409"
        fill="#1E2628"
      />
      <path
        d="M6.49669 5.46282L7.09162 7.50156L6.73327 7.57657L5.9783 6.22749"
        fill="#1E2628"
      />
      <path
        d="M11.8452 6.19725L11.2642 6.11105C11.4405 5.00715 12.2999 4.54813 12.7231 4.38579C12.7289 3.94692 12.867 3.0076 13.8724 2.45565L14.1635 2.94938C13.1952 3.48118 13.3123 4.50894 13.3181 4.5526L13.3494 4.80339L13.0943 4.86385C13.0513 4.87392 12.0146 5.13366 11.8452 6.19725Z"
        fill="#1E2628"
      />
      <path
        d="M13.2427 4.77878L12.8078 4.39589C12.8461 4.35558 13.7553 3.40843 15.2664 3.5898L15.1933 4.15294C13.9942 4.00963 13.2728 4.74743 13.2427 4.77878Z"
        fill="#1E2628"
      />
      <path
        d="M12.86 6.96418C12.1456 5.6666 12.7383 4.50784 12.7638 4.45858L13.288 4.71496L13.0259 4.58621L13.2891 4.71384C13.2694 4.75191 12.8125 5.6666 13.3796 6.69772L12.86 6.96418Z"
        fill="#1E2628"
      />
      <path
        d="M11.1054 5.12807L10.7354 4.68695C11.2573 4.27943 11.8626 4.12493 12.2419 4.06671C12.163 3.70397 12.0969 3.10276 12.3057 2.487L12.8646 2.66277C12.6049 3.43192 12.889 4.2089 12.8913 4.21674L13.0316 4.59179L12.6188 4.59739C12.6107 4.59851 11.7571 4.61866 11.1054 5.12807Z"
        fill="#1E2628"
      />
      <path
        d="M10.5 9.64666C9.6592 9.64666 8.97498 8.98612 8.97498 8.17443C8.97498 7.36274 9.6592 6.70219 10.5 6.70219C11.3408 6.70219 12.025 7.36274 12.025 8.17443C12.0262 8.98612 11.3408 9.64666 10.5 9.64666ZM10.5 7.2687C9.98276 7.2687 9.56295 7.6751 9.56295 8.17331C9.56295 8.67264 9.98392 9.07792 10.5 9.07792C11.0172 9.07792 11.437 8.67152 11.437 8.17331C11.4382 7.6751 11.0172 7.2687 10.5 7.2687Z"
        fill="#1E2628"
      />
      <path
        d="M10.5 7.26869C9.98274 7.26869 9.56293 7.6751 9.56293 8.1733C9.56293 8.67263 9.9839 9.07792 10.5 9.07792C11.0172 9.07792 11.437 8.67151 11.437 8.1733C11.4382 7.6751 11.0172 7.26869 10.5 7.26869Z"
        fill="#FBB040"
      />
    </svg>
  );
}