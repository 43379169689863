import { AxiosError } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { tokensApi } from 'services/tokens'
import { useMainStore } from 'store'
import { Button } from 'ui-kit/Button'
import { RightArrowWithTail } from 'ui-kit/icons/RightArrowWithTail'
import { calcAmountOfTokens, calcAmountOfTokensToShow } from 'utils'
import { PaymentSelect } from '../PaymentSelect'
import { PaymentFormType } from './scheme'
import { useUserStore } from 'store/user'
import { PaymentMethod } from 'screens/Landing/constants/methods'
import { useTranslation } from 'react-i18next'
import { IPaymentLimit } from 'types/Tokens'

interface SelectAssetProps {
  paymentInUSD: number
  preselectedMethod: PaymentMethod
}

export function SelectAsset({
  paymentInUSD,
  preselectedMethod
}: SelectAssetProps) {
  const [canEnterRefCode] = useUserStore((state) => [state.canEnterRefCode])
  const form = useFormContext<PaymentFormType>()
  const [isLoading, setIsLoading] = useState(false)
  const [currentRound, currentBonus, settings] = useMainStore((state) => [
    state.currentRound,
    state.currentBonus,
    state.settings
  ])
  const [isLimitLoading, setIsLimitLoading] = useState(false)
  const [paymentLimit, setPaymentLimit] = useState<IPaymentLimit | null>(null);
  const { t } = useTranslation()
  if (!form) throw new Error('SelectAsset must be used within a FormProvider')

  const fetchMinLimit = useCallback(async (token: string) => {
    try {
      setIsLimitLoading(true)
      const data = await tokensApi.getPaymentLimit(token)
      setPaymentLimit(data)
    } catch (error) {
      console.warn(error)
    } finally {
      setIsLimitLoading(false)
    }
  }, [])

  const paymentMethod = form.watch('paymentMethod')
  const refCode = form.watch('refCode')

  useEffect(() => {
    if (preselectedMethod) form.setValue('paymentMethod', preselectedMethod)

    fetchMinLimit(preselectedMethod.nowPaymentsId)
  }, [preselectedMethod])

  const handleSelectAsset = async () => {
    const tokens = calcAmountOfTokens(
      paymentInUSD,
      currentRound?.tokenPrice,
      currentRound?.maxPurchasePerWallet
    )
    const { paymentMethod, refCode } = form.getValues()
    form.clearErrors('root')
    setIsLoading(true)
    try {
      const res = await tokensApi.sendOrder(
        tokens,
        paymentMethod.nowPaymentsId,
        refCode?.length > 0 ? refCode : undefined
      )

      if (!res) {
        throw new Error('Something went wrong')
      }

      form.setValue('payAmount', res.invoice.payAmount)
      form.setValue('payAddress', res.invoice.payAddress)
      form.setValue('orderId', res.order.id)

      form.setValue('step', 'sendDeposit')
    } catch (error) {
      if (error instanceof AxiosError) {
        form.setError('root', {
          type: 'custom',
          message: error.response?.data.message
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div className='flex flex-col items-center gap-2'>
        <div className='my-auto px-3 py-1.5 rounded-lg bg-[#F9F1F5] font-medium text-xs leading-5 text-[#7F6C7B]'>
          {canEnterRefCode ? 2 : 1} {t('of')} {canEnterRefCode ? 3 : 2}{' '}
          {t('steps')}
        </div>
        <h2 className='font-medium text-2xl leading-snug text-[#512248]'>
          {t('selectAsset.title')}
        </h2>
      </div>
      {settings?.nowPaymentsLimitMessageEnabled && (
        <>
          <div className='col-span-2 flex gap-2.5 px-5 py-3.5 rounded-lg bg-[#F9F1F5] font-medium text-sm leading-5 text-[#AC828C]'>
            {isLimitLoading && 'Limits are loading...'}
            {!isLimitLoading &&
              `Min amount to pay: ${paymentLimit && paymentLimit.minAmount} ${paymentMethod.currency}`}
          </div>
          <div className='col-span-2 flex gap-2.5 px-5 py-3.5 rounded-lg bg-[#F9F1F5] font-medium text-sm leading-5 text-[#AC828C]'>
            {isLimitLoading && 'Limits are loading...'}
            {!isLimitLoading &&
              `Min amount to pay: ${paymentLimit && (paymentLimit.minAmount * paymentLimit.usdPrice + 0.02).toFixed(2)} USD`}
          </div>
        </>
        
      )}
      <div className='flex flex-col gap-2'>
        <label className='font-medium text-xs leading-5 text-[#978D95]'>
          {t('selectAsset.receive')}
        </label>
        <label className='flex justify-between w-full p-4 rounded-xl bg-[#F2F2F2]'>
          <input
            type='text'
            className='grow bg-transparent font-medium text-base leading-snug outline-none hero-form-field placeholder:text-[#512248]'
            id='youReceive'
            disabled
            readOnly
            value={`${calcAmountOfTokensToShow(
              paymentInUSD,
              currentRound,
              currentBonus,
              refCode?.length > 0
            )} $PARCHA`}
          />
        </label>
      </div>
      <div className='flex flex-col gap-2'>
        <label className='font-medium text-xs leading-5 text-[#978D95]'>
          {t('selectAsset')}
        </label>
        <Controller
          name='paymentMethod'
          control={form.control}
          render={({ field }) => (
            <PaymentSelect
              selected={field.value}
              onSelect={(val) => {
                field.onChange(val)
                form.clearErrors('root')
                fetchMinLimit(val.nowPaymentsId)
              }}
            />
          )}
        />
        {form.formState.errors.root && (
          <div className='text-red-500'>
            {form.formState.errors.root.message}
          </div>
        )}
      </div>
      <Button
        className='h-[3.375rem]'
        variant='primary'
        onClick={handleSelectAsset}
        disabled={isLoading || isLimitLoading}
      >
        {isLoading || isLimitLoading ? (
          'Loading...'
        ) : (
          <>
            {t('nextStep')}
            <RightArrowWithTail />
          </>
        )}
      </Button>
    </>
  )
}
