import { scrollTo } from 'utils';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  place: 'header' | 'burger';
  setIsOpen?: (val: boolean) => void;
};

export const auditLink = `${window.location.origin}/paradisechainaudit.pdf`;

export const Navigation: React.FC<Props> = ({ place, setIsOpen }) => {
  const handleClick = (e: MouseEvent, id: string) => {
    e.preventDefault();
    setIsOpen && setIsOpen(false);
    scrollTo(document.getElementById(id));
  };
  const { t } = useTranslation()

  return (
    <nav
      className={
        place === 'burger'
          ? 'flex flex-col items-center gap-[2rem] font-medium [&_a]:text-[1rem] [&_a]:whitespace-nowrap px-4 leading-5 text-[#512248]'
          : 'lg:hidden flex items-center gap-[2rem] font-medium [&_a]:text-[1em] [&_a]:whitespace-nowrap px-4 leading-5 text-[#512248]'
      }
    >
      <a href='#features' onClick={(e) => handleClick(e, 'features')}>
        {t('navigation.features')}
      </a>

      <a
        href='https://paradisechain.gitbook.io/paradisechain'
        target='_blank'
        rel='noopener noreferrer'
      >
        {t('navigation.whitepaper')}
      </a>

      <a href='#tokenomics' onClick={(e) => handleClick(e, 'tokenomics')}>
        {t('navigation.tokenomics')}
      </a>

      <a href='#roadmap' onClick={(e) => handleClick(e, 'roadmap')}>
        {t('navigation.roadmap')}
      </a>

      <a href={auditLink} target='_blank' rel='noopener noreferrer'>
        {t('navigation.audit')}
      </a>

      <a
        href='https://paradisechain.gitbook.io/paradisechain/strategy-and-ico/how-to-join-us'
        target='_blank'
        rel='noopener noreferrer'
      >
        {t('navigation.howBuy')}
      </a>
    </nav>
  );
};
