export interface PaymentMethod {
  name: string;
  currency: string;
  icon?: string;
  nowPaymentsId: string;
}

export const paymentMethods: PaymentMethod[] = [
  {
    name: 'Bitcoin',
    currency: 'BTC',
    icon: require('assets/coins/bitcoin.webp'),
    nowPaymentsId: 'BTC',
  },
  {
    name: 'Solana',
    currency: 'SOL',
    icon: require('assets/coins/solana.webp'),
    nowPaymentsId: 'SOL',
  },
  {
    name: 'Tether',
    currency: 'USDT',
    icon: require('assets/coins/tether.webp'),
    nowPaymentsId: 'USDTERC20',
  },
  {
    name: 'Binance Coin',
    currency: 'BNB',
    icon: require('assets/coins/binanceCoin.webp'),
    nowPaymentsId: 'BNBBSC',
  },
  {
    name: 'Binance USD',
    currency: 'BUSD',
    icon: require('assets/coins/busd.webp'),
    nowPaymentsId: 'BUSDBSC',
  },
  {
    name: 'USD Coin',
    currency: 'USDC',
    icon: require('assets/coins/usdc.webp'),
    nowPaymentsId: 'USDC',
  },
  {
    name: 'Ethereum',
    currency: 'ETH',
    icon: require('assets/coins/ethereum.webp'),
    nowPaymentsId: 'ETH',
  },
  {
    name: 'Dogecoin',
    currency: 'DOGE',
    icon: require('assets/coins/dodgeCoin.webp'),
    nowPaymentsId: 'DOGE',
  },
  {
    name: 'Uniswap',
    currency: 'UNI',
    icon: require('assets/coins/uniswap.webp'),
    nowPaymentsId: 'UNI',
  },
  {
    name: 'Litecoin',
    currency: 'LTC',
    icon: require('assets/coins/litecoin.webp'),
    nowPaymentsId: 'LTC',
  },
  {
    name: 'Tron',
    currency: 'TRX',
    icon: require('assets/coins/tron.webp'),
    nowPaymentsId: 'TRX',
  },
  {
    name: 'Shiba Inu',
    currency: 'SHIB',
    icon: require('assets/coins/shiba.webp'),
    nowPaymentsId: 'SHIB',
  },
  {
    name: 'Polygon Matic',
    currency: 'MATIC',
    icon: require('assets/coins/polygon.webp'),
    nowPaymentsId: 'MATICMAINNET',
  },
];
