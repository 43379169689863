import { motion, useWillChange } from 'framer-motion';
import { useConnectedWalletIcon } from 'hooks/useConnectedWalletIcon';
import { MouseEvent, useEffect, useState } from 'react';
import { Button } from 'ui-kit/Button';
import { Logo } from 'ui-kit/icons/Logo';
import { LogoWithText } from 'ui-kit/icons/LogoWithText';
import { TriangleDown } from 'ui-kit/icons/TriangleDown';
import { Wallet } from 'ui-kit/icons/Wallet';
import { formatAddress, scrollTo } from 'utils';
import { useAccount } from 'wagmi';
import { BalanceDialog } from '../BalanceDialog';
import { WalletPopover } from './WalletPopover';
import { Navigation } from './components/Navigation';
import { BurgerMenu } from './components/BurgerMenu';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { LocationSelector } from '../LocationSelector';
import { useTranslation } from 'react-i18next';
import { locales } from 'constants/location';

export function Header() {
  const { isConnected, address } = useAccount();
  const walletIcon = useConnectedWalletIcon();
  const willChange = useWillChange();
  const { openConnectModal } = useConnectModal();
  const { i18n, t } = useTranslation();
  const [isOpenedOptions, setOpenedOptions] = useState(false);
  const [location, setLocation] = useState(i18n.language);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
      setLocation(savedLanguage);
    } else {
      localStorage.setItem('language', i18n.language);
    }
  }, []);

  const handleSetLocation = (loc: string) => {
    i18n.changeLanguage(loc);
    setLocation(loc);
    localStorage.setItem('language', loc);
    setOpenedOptions(false);
  };

  const handleClick = (e: MouseEvent, id: string) => {
    e.preventDefault();
    scrollTo(document.getElementById(id));
  };

  return (
    <motion.header
      initial={{ opacity: 0, pointerEvents: 'none' }}
      animate={{ opacity: 1, pointerEvents: 'auto' }}
      transition={{ duration: 2, delay: 5 }}
      style={{ willChange }}
      className='relative flex justify-between items-center pl-12 pr-10 pt-5 z-10 sm:pt-2 sm:px-2'
    >
      <LogoWithText />

      <Navigation place='header' />

      <div className='flex flex-nowrap justify-end relative gap-4'>
        <LocationSelector
          isOpenedOptions={isOpenedOptions}
          setOpenedOptions={setOpenedOptions}
          location={location}
          setLocation={handleSetLocation}
          locations={locales}
          type={'location'}
        />
        {isConnected && address ? (
          <div className='flex gap-2'>
            <BalanceDialog>
              <Button className='h-[3.375rem] text-[#512248] md:p-0 md:w-[3.375rem] sm:h-[2.5rem] sm:!w-[2.5rem]'>
                <Logo width={16} height={16} />
                <span className='md:hidden'>{t('header.button.balance')}</span>
              </Button>
            </BalanceDialog>
            <WalletPopover>
              <Button
                className='h-[3.375rem] md:p-0 md:w-[3.375rem] sm:h-[2.5rem] sm:!w-[2.5rem]'
                variant='primary'
              >
                <img src={walletIcon} className='w-5 h-5' alt='' />
                <span className='md:hidden'>
                  {formatAddress(address, 4, 3)}
                </span>
                <TriangleDown
                  width={16}
                  height={16}
                  color='white'
                  className='md:hidden'
                />
              </Button>
            </WalletPopover>
          </div>
        ) : (
          <Button
            onClick={openConnectModal}
            className='h-[3.375rem] sm:h-[2.5rem] md:w-[3.375rem] sm:!w-[2.5rem] md:p-0'
            variant='primary'
          >
            <Wallet color='white' />
            <span className='md:hidden'>{t('header.button.wallet')}</span>
          </Button>
        )}
        <BurgerMenu />
      </div>
    </motion.header>
  );
}
