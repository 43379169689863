import { AnimationProps, motion, useWillChange } from 'framer-motion';
import { useMemo } from 'react';
import { TokenUtilityType } from './data';
import clsx from 'clsx';
import '../Features/styles.css';

interface MemberProps {
  page: number;
  currentPage: number;
  data: TokenUtilityType;
}

export function TokenUtilityCard({ page, currentPage, data }: MemberProps) {
  const willChange = useWillChange();

  const styles = useMemo<AnimationProps['animate']>(() => {
    if (page === currentPage) {
      return {
        position: 'relative',
        visibility: 'visible',
      };
    }

    if (page - 1 === currentPage) {
      return {
        position: 'absolute',
        x: 'calc(100% + 33px)',
        scale: 0.67,
        opacity: 0.7,
        rotateZ: "15deg",
        visibility: 'visible',
      };
    }

    if (page + 1 === currentPage) {
      return {
        position: 'absolute',
        x: 'calc(-100% - 33px)',
        scale: 0.67,
        opacity: 0.7,
        rotateZ: "-15deg",
        visibility: 'visible',
      };
    }

    return {
      position: 'absolute',
      x: currentPage > page ? '-200%' : '200%',
      scale: 0.33,
      opacity: 0,
      rotateZ: currentPage > page ? '-30deg' : '30deg',
      transitionEnd: {
        visibility: 'hidden',
      },
    };
  }, [page, currentPage]);

  return (
    <motion.div
      key={page}
      animate={styles}
      style={{ willChange }}
      transition={{
        duration: 0.2,
      }}
      className={clsx(
        'mx-auto flex justify-center items-center flex-col gap-6 pt-8 p-6 sm:p-2 rounded-3xl sm:w-[280px] h-[300px]',
        page % 3 === 0 && 'bg-card1',
        page % 3 === 1 && 'bg-card2 text-white',
        page % 3 === 2 && 'bg-card3 text-white'
      )}
    >
      <div className="flex justify-center items-center text-center flex-col gap-2 font-medium text-[21px] drop-shadow">
        <span className="flex items-end max-w-[320px] sm:max-w-[300px] sm:h-fit">
          {data.heading}
        </span>
      </div>
      <div className="w-[320px] sm:max-w-[200px] sm:h-fit rounded-[1.75rem] text-center text-[14px]">
        {data.text}
      </div>
    </motion.div>
  );
}
