import { SVGAttributes } from "react";

export function Copy({ color = "#512248", ...props }: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <g id="copy" clipPath="url(#clip0_409_218)">
        <path
          id="Vector"
          d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M3.3335 9.99992H2.66683C2.31321 9.99992 1.97407 9.85944 1.72402 9.60939C1.47397 9.35935 1.3335 9.02021 1.3335 8.66659V2.66659C1.3335 2.31296 1.47397 1.97382 1.72402 1.72378C1.97407 1.47373 2.31321 1.33325 2.66683 1.33325H8.66683C9.02045 1.33325 9.35959 1.47373 9.60964 1.72378C9.85969 1.97382 10.0002 2.31296 10.0002 2.66659V3.33325"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_409_218">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}