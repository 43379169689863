import usd from 'assets/coins/usd.webp'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { MAX_AMOUNT_IN_USD_PER_WALLET } from 'constants/sale'
import { Button } from 'ui-kit/Button'
import { Logo } from 'ui-kit/icons/Logo'
import { LogoWithBg } from 'ui-kit/icons/LogoWithBg'
import { useAccount } from 'wagmi'
import { PaymentDialog } from '../PaymentDialogs'
import { calcAmountOfTokensToShow, calcStatus } from 'utils'
import { useMainStore } from 'store'
import { ConnectEmailDialog } from '../ConnectEmailDialog'
import { useUserStore } from 'store/user'
import clsx from 'clsx'
import { PaymentSelect } from '../PaymentSelect'
import {
  PaymentMethod,
  paymentMethods
} from 'screens/Landing/constants/methods'
import { useTranslation } from 'react-i18next'
import { tokensApi } from 'services/tokens'
import { IPaymentLimit } from 'types/Tokens'
import { Loader } from 'components/Loader'

interface HeroFormType {
  value: string
  method: PaymentMethod
  paymentMethod: PaymentMethod
}

const YOU_RECIEVE_TEXT_SIZE_POINT = 10000

export function HeroForm() {
  const form = useForm<HeroFormType>({
    defaultValues: {
      value: '',
      paymentMethod: paymentMethods[0]
    }
  })
  const [currentRound, currentBonus, currentReferralProgram] = useMainStore(
    (state) => [
      state.currentRound,
      state.currentBonus,
      state.currentReferralProgram
    ]
  )
  const value = parseFloat(form.watch('value').replace(',', '.'))
  const paymentMethod = form.watch('paymentMethod')
  const youReceive = calcAmountOfTokensToShow(
    value,
    currentRound,
    currentBonus,
    false
  )
  const isSaleEnded = calcStatus() !== 'ongoingSale'
  const { t } = useTranslation()
  const { isConnected } = useAccount()
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false)
  const [isConnectEmailDialogOpen, setIsConnectEmailDialogOpen] =
    useState(false)
  const [isLoading, user] = useUserStore((state) => [
    state.isLoading,
    state.user
  ])
  const [shouldConnectEmail, setShouldConnectEmail] = useState(
    !user.email || user.email.length <= 0
  )
  const [paymentLimit, setPaymentLimit] = useState<IPaymentLimit | null>(null);
  const [isLoadingPaymentLimit, setIsLoadingPaymentLimit] = useState(false);
  const minAmountInUsd = paymentLimit ? Math.ceil((paymentLimit?.usdPrice * paymentLimit?.minAmount + 0.02) * 100) / 100 : 0
  const isDisabledBuyButton = isLoadingPaymentLimit || !value || value < 1 || minAmountInUsd > value || isPaymentDialogOpen || !isConnected || isSaleEnded || isLoading || !!user.kicked

  const fetchPaymentLimit = useCallback(async (token: string) => {
    try {
      setIsLoadingPaymentLimit(true)
      const data = await tokensApi.getPaymentLimit(token);
      if (data) {
        setPaymentLimit(data)
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoadingPaymentLimit(false)
    }
  }, []);

  useEffect(() => {
    if (value) {
      fetchPaymentLimit(paymentMethod.nowPaymentsId)
    } else {
      setPaymentLimit(null)
    }
  }, [paymentMethod.currency, value])

  useEffect(() => {
    if (isLoading) return

    setShouldConnectEmail(!user.email || user.email.length <= 0)
  }, [isLoading, user.email])


  const handleBuyNow = async () => {
    if (!isConnected) return

    if (shouldConnectEmail) {
      setIsConnectEmailDialogOpen(true)
      return
    }

    setIsPaymentDialogOpen(true)
  }

  const handlePaymentDialogOpen = (val: boolean) => {
    setIsPaymentDialogOpen(val)

    if (!val) form.reset()
  }

  const unactiveMethods = paymentMethods.filter(
    (el) => el.name !== paymentMethod.name
  )

  const onSetMethod = (method: PaymentMethod) => {
    form.setValue('paymentMethod', method)
  }

  return (
    <form
      onSubmit={form.handleSubmit(handleBuyNow)}
      className='flex flex-col max-w-[446px] mx-auto px-2'
    >
      <div className='flex mb-[.875em] items-center justify-between sm:text-[0.8rem]'>
        <div className='px-[.75em] py-[.5em] flex items-center justify-between gap-[.5em] rounded-full bg-white bg-transparent '>
          <img
            className='w-[1.5em] h-[1.5em]'
            src={paymentMethod.icon}
            alt={paymentMethod.name}
          />

          <span className='font-formatDJR-m font-medium text-[0.875em] text-black'>
            {paymentMethod.name}{' '}
            <span className='text-[#B2A7B0]'>({paymentMethod.currency})</span>
          </span>
        </div>

        {unactiveMethods.slice(0, 5).map((el) => {
          return (
            <button type='button' onClick={() => onSetMethod(el)}>
              <img className='w-[2em] h-[2em]' src={el.icon} alt={el.name} />
            </button>
          )
        })}

        <Controller
          name='paymentMethod'
          control={form.control}
          render={({ field }) => (
            <PaymentSelect
              selected={field.value}
              onSelect={(val) => field.onChange(val)}
              type={'preselect'}
              filteredMethods={unactiveMethods.slice(5)}
            />
          )}
        />
      </div>

      <div className='flex flex-col gap-1 mb-[1.5rem]'>
        <div className='grid grid-cols-2 gap-2'>
          <label className='flex flex-col gap-2 sm:gap-1 px-3 py-3 sm:py-1 sm:px-[.5rem] border border-white bg-white bg-opacity-40 rounded-xl'>
            <span className='font-medium text-sm leading-4 text-[#B4A4A8]'>
              {t('heroForm.pay')}
            </span>
            <div className='flex items-center gap-1'>
              <img
                src={paymentMethod.icon}
                alt=''
                className='w-[1.5em] h-[1.5em]'
              />
              <Controller
                name='value'
                control={form.control}
                rules={{
                  required: 'This field is required',
                  min: {
                    value: currentRound?.minAmount || 50,
                    message: `The amount must be greater than or equal to ${currentRound?.minAmount || 50
                      }$`
                  },
                  max: {
                    value:
                      currentRound?.maxPurchasePerWallet ||
                      MAX_AMOUNT_IN_USD_PER_WALLET,
                    message: `The amount must be less than or equal to ${currentRound?.maxPurchasePerWallet ||
                      MAX_AMOUNT_IN_USD_PER_WALLET
                      }$`
                  }
                }}
                render={({ field }) => (
                  <div className={clsx('relative', value && 'before:content-["$"] before:absolute before:top-[50%] before:left-0 text-black before:translate-y-[-50%]')}>
                    <input
                      type='text'
                      className={clsx('hero-form-field w-full bg-transparent outline-none font-medium text-lg sm:text-sm leading-normal text-black placeholder:text-black', value && 'pl-[10px]')}
                      placeholder={`0.00 USD`}
                      {...field}
                      onChange={(e) => {
                        const amount = e.target.value

                        if (!amount || amount.match(/^\d{1,}([.,]{1}\d{0,4})?$/)) {
                          field.onChange(e)
                        }
                      }}
                    />
                  </div>
                )}
              />
            </div>
          </label>
          <label className='flex flex-col gap-2 sm:gap-1 px-3 py-3 sm:py-1 sm:px-[.5rem] bg-white bg-opacity-40 rounded-xl'>
            <span className='font-medium text-sm leading-4 text-[#B4A4A8]'>
              {t('heroForm.receive')}
            </span>
            <div className='flex items-center gap-1'>
              <LogoWithBg width={28} height={28} className='min-w-[1.75rem]' />
              <input
                type='text'
                className={clsx(
                  'hero-form-field w-full bg-transparent outline-none font-medium leading-normal text-black placeholder:text-black',
                  youReceive < YOU_RECIEVE_TEXT_SIZE_POINT
                    ? ' sm:text-sm text-lg'
                    : 'text-[1rem] sm:text-[0.8rem]'
                )}
                readOnly
                disabled
                value={`${youReceive || 0} $PARCHA`}
              />
            </div>
          </label>
          {currentBonus && (
            <div className='col-span-2 flex gap-2.5 px-5 py-3.5 rounded-lg bg-white bg-opacity-20 font-medium text-sm leading-5 text-[#AC828C]'>
              {currentBonus.description}
            </div>
          )}
          {currentReferralProgram?.enabled && (
            <div className='col-span-2 flex gap-2.5 px-5 py-3.5 rounded-lg bg-white bg-opacity-20 font-medium text-sm leading-5 text-[#AC828C]'>
              {currentReferralProgram?.description}
            </div>
          )}
        </div>
        {paymentLimit && <p className='text-[20px]'>Minimum amount to pay: {minAmountInUsd} USD</p>}
      </div>
      
      {form.formState.errors.value && (
        <div className='mb-3 -mt-3 text-red-500'>
          {form.formState.errors.value.message}
        </div>
      )}
      <Button
        className='h-[54px]'
        variant='primary'
        disabled={isDisabledBuyButton}
      >
        {isLoadingPaymentLimit ? <Loader /> : <><Logo /> {t('heroForm.buyNow')}</>}
      </Button>
      <ConnectEmailDialog
        isOpen={isConnectEmailDialogOpen}
        onOpenChange={setIsConnectEmailDialogOpen}
        onSuccessfulConnect={(shouldContinue) => {
          setIsConnectEmailDialogOpen(false)
          setShouldConnectEmail(false)
          handlePaymentDialogOpen(shouldContinue)
        }}
      />
      <PaymentDialog
        open={isPaymentDialogOpen}
        onOpenChange={handlePaymentDialogOpen}
        paymentInUSD={value}
        paymentMethod={paymentMethod}
      />
    </form>
  )
}
