import { useTimeDifference } from 'hooks/useTimeDifference';
import { useMainStore } from 'store';

export function Timer() {
  const [currentRound, isLoading] = useMainStore((s) => [
    s.currentRound,
    s.isLoading,
  ]);
  const { days, hours, minutes, seconds } = useTimeDifference(
    currentRound ? new Date(currentRound?.finishAt).getTime() : 0,
    !isLoading
  );

  return (
    <div className='flex items-center mx-auto gap-2'>
      <div className='flex justify-center items-center w-16 h-16 sm:w-14 sm:h-14 rounded-xl bg-white font-bold leading-none text-2xl sm:text-xl'>
        <div>
          {days}
          <span className='ml-1 font-normal text-base sm:text-[0.8rem] leading-5 pt-[8.5px] pb-[2.5px] text-[#B2A7B0]'>
            d
          </span>
        </div>
      </div>
      <div className='flex justify-center items-center w-16 h-16 sm:w-14 sm:h-14 rounded-xl bg-white font-bold leading-none text-2xl sm:text-xl'>
        <div>
          {hours}
          <span className='ml-1 font-normal text-base sm:text-[0.8rem] leading-5 pt-[8.5px] pb-[2.5px] text-[#B2A7B0]'>
            h
          </span>
        </div>
      </div>
      <div className='flex justify-center items-center w-16 h-16 sm:w-14 sm:h-14 rounded-xl bg-white font-bold leading-none text-2xl sm:text-xl'>
        <div>
          {minutes}
          <span className='ml-1 font-normal text-base sm:text-[0.8rem] leading-5 pt-[8.5px] pb-[2.5px] text-[#B2A7B0]'>
            m
          </span>
        </div>
      </div>
      <div className='flex justify-center items-center w-16 h-16 sm:w-14 sm:h-14 rounded-xl bg-white font-bold leading-none text-2xl sm:text-xl'>
        <div>
          {seconds}
          <span className='ml-1 font-normal text-base sm:text-[0.8rem] leading-5 pt-[8.5px] pb-[2.5px] text-[#B2A7B0]'>
            s
          </span>
        </div>
      </div>
    </div>
  );
}
