import { ReactNode } from 'react';
import { Info } from 'ui-kit/icons/Info';

export function ErrorAlert({ children }: { children?: ReactNode }) {
  return (
    <div className="flex items-center gap-2.5 p-3 rounded-lg bg-[#FFE7EA] font-normal text-xs leading-[0.875rem] text-[#92304D]">
      <Info color="#92304D" />
      <span>{children}</span>
    </div>
  );
}
