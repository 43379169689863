import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { ArrowLeft } from "ui-kit/icons/ArrowLeft";
import { tokenUtilityMockData } from "./data";
import { TokenUtilityCard } from "./Card";
import { useTranslation } from 'react-i18next';

export function TokenUtility() {
  const [page, setPage] = useState(0);
  const { t } = useTranslation()
  const tokenUtilityData = tokenUtilityMockData()

  return (
    <div className="overflow-hidden px-[4.75rem] lg:px-[2rem] sm:!px-0 pb-[3.25rem] text-[#512248] sm:pt-[3rem]">
      <div id="utility">
        <h2 className="flex justify-center items-center pl-4 sm:pl-0 gap-2 font-medium text-[3.375rem] lg:text-[3rem] leading-none tracking-[1px]">
          {t('tokenUtility.title')}
        </h2>
        <div className="relative mt-24 h-[200px] xl-1440:h-[350px] flex justify-between items-center xl-1440:items-start xl-1440:justify-center">
          <AnimatePresence mode="wait">
            {page - 1 >= 0 ? (
              <motion.button
                key="team-prev-btn"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="flex items-center w-10 gap-6 z-10 xl-1440:absolute bottom-0 left-0"
                onClick={() => setPage(page - 1)}
              >
                <ArrowLeft />
              </motion.button>
            ) : (
              <div className="w-10" />
            )}
          </AnimatePresence>
          <div className="flex items-start">
            {tokenUtilityData.map((el, i) => (
              <TokenUtilityCard key={i} currentPage={page} page={i} data={el} />
            ))}
          </div>
          <AnimatePresence mode="wait">
            {page + 1 < tokenUtilityData.length ? (
              <motion.button
                key="team-next-btn"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="flex justify-end items-center w-10 xl-1440:absolute bottom-0 right-0"
                onClick={() => setPage(page + 1)}
              >
                <ArrowLeft className="rotate-180" />
              </motion.button>
            ) : (
              <div className="w-10" />
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}