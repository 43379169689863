interface MarqueeContentMap {
  iconSrc: string;
  text: string;
}

export const marqueeContentMap: MarqueeContentMap[] = [
	{
		iconSrc: require('assets/coins/solana.webp'),
		text: 'Solana',
	},
	{
		iconSrc: require('assets/coins/bitcoinComIcon.webp'),
		text: 'Bitcoin.com',
	},
  {
		iconSrc: require('assets/coins/polygon.webp'),
    text: 'Polygon',
  },
  {
		iconSrc: require('assets/coins/cryptoWisserIcon.webp'),
    text: 'CryptoWisser',
  },
  {
		iconSrc: require('assets/coins/finboldIcon.webp'),
    text: 'Finbold',
  },
	{
		iconSrc: require('assets/coins/bitcoin.webp'),
		text: 'Bitcoin',
	},
	{
		iconSrc: require('assets/coins/cryptoDailyIcon.webp'),
		text: 'CryptoDaily',
	},
  {
    iconSrc: require('assets/coins/uniswap.webp'),
    text: 'Uniswap',
  },
  {
    iconSrc: require('assets/coins/coinSpeaker.webp'),
    text: 'CoinSpeaker',
  },
];
