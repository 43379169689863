import {
  PopoverContent,
  PopoverPortal,
  Root as PopoverRoot,
  PopoverTrigger,
} from '@radix-ui/react-popover';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { Search } from 'ui-kit/icons/Search';
import { PaymentMethod, paymentMethods } from '../../constants/methods';
import { Tick } from 'ui-kit/icons/Tick';
import { TriangleDown } from 'ui-kit/icons/TriangleDown';
import { ScrollArea } from 'ui-kit/ScrollArea';
import { useTranslation } from 'react-i18next';

interface TokensSelectProps {
  selected: PaymentMethod;
  onSelect: (method: PaymentMethod) => void;
  type?: 'preselect';
  filteredMethods?: PaymentMethod[];
}

export function PaymentSelect({
  selected,
  onSelect,
  type,
  filteredMethods,
}: TokensSelectProps) {
  const [isOpen, setOpen] = useState(false);
  const [searchName, setSearchName] = useState('');
  const { t } = useTranslation()

  const handleOpenChange = (val: boolean) => {
    setOpen(val);
    if (!val) {
      setSearchName('');
    }
  };

  return (
    <PopoverRoot open={isOpen} onOpenChange={handleOpenChange}>
      {type === 'preselect' ? (
        <PopoverTrigger className='w-[2em] h-[2em] rounded-full bg-white outline-none flex justify-center items-center'>
          <TriangleDown color='black' />
        </PopoverTrigger>
      ) : (
        <PopoverTrigger className='flex justify-between w-full p-4 rounded-xl bg-[#F2F2F2]'>
          <div className='flex items-center gap-2 font-bold text-sm leading-5'>
            <img
              src={selected.icon}
              className='w-6 h-6'
              alt={selected.currency}
            />
            {selected.name}{' '}
            <span className='text-xs text-[#B2A7B0]'>
              ({selected.currency})
            </span>
          </div>
          <TriangleDown color='black' />
        </PopoverTrigger>
      )}
      <AnimatePresence>
        {isOpen && (
          <PopoverPortal forceMount>
            <PopoverContent
              asChild
              avoidCollisions={false}
              sideOffset={16}
              align='center'
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={clsx(
                  'relative flex flex-col gap-4 p-4 rounded-2xl bg-white z-[500] popover-shadow',
                  type === 'preselect' &&
                    'min-w-[260px] -translate-x-[117px] -translate-y-[10px]'
                )}
                style={{
                  width: 'var(--radix-popover-trigger-width)',
                  height: 'var(--radix-popover-content-available-height)',
                  maxHeight: '264px',
                  minHeight: '200px',
                }}
              >
                <label className='flex items-center gap-4 p-3 pl-4 rounded-lg bg-[#F2F2F2] cursor-text'>
                  <Search />
                  <input
                    type='text'
                    className='grow font-medium text-sm leading-normal text-[#512248] outline-none bg-transparent placeholder:text-[#512248]'
                    placeholder={t('paymentSelect.search')}
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                  />
                </label>
                <ScrollArea>
                  <div className='flex flex-col gap-4 overflow-auto'>
                    {(filteredMethods || paymentMethods).map((method) => (
                      <button
                        key={method.currency}
                        className={clsx(
                          'gap-4 font-medium text-left text-sm leading-normal text-[#512248]',
                          searchName !== '' &&
                            !method.name.toLowerCase().includes(searchName) &&
                            !method.currency.toLowerCase().includes(searchName)
                            ? 'hidden'
                            : 'flex'
                        )}
                        onClick={() => {
                          onSelect(method);
                          handleOpenChange(false);
                        }}
                      >
                        <img src={method.icon} className='w-5 h-5' alt='' />
                        <span>
                          {method.name}{' '}
                          <span className='text-xs text-[#B2A7B0]'>
                            ({method.currency})
                          </span>
                        </span>
                        {selected.currency === method.currency && (
                          <span className='flex justify-center items-center ml-auto w-5 h-5 rounded-full bg-[#DCF4CA]'>
                            <Tick width={12} height={12} color='#96AD85' />
                          </span>
                        )}
                      </button>
                    ))}
                  </div>
                </ScrollArea>
              </motion.div>
            </PopoverContent>
          </PopoverPortal>
        )}
      </AnimatePresence>
    </PopoverRoot>
  );
}
