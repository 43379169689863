import i18next from 'i18next';

export interface TokenUtilityType {
  heading: string;
  text: string;
}

export const tokenUtilityMockData = () => [
  {
    heading: i18next.t('tokenUtility.privilegedStatus'),
    text: i18next.t('tokenUtility.privilegedStatus.description')
  },
  {
    heading: i18next.t('tokenUtility.referralIncentives'),
    text: i18next.t('tokenUtility.referralIncentives.description')
  },
  {
    heading: i18next.t('tokenUtility.contractualPenalties'),
    text: i18next.t('tokenUtility.contractualPenalties.description')
  },
  {
    heading: i18next.t('tokenUtility.exclusiveAccess'),
    text: i18next.t('tokenUtility.exclusiveAccess.description')
  },
  {
    heading: i18next.t('tokenUtility.communityInvolvement'),
    text: i18next.t('tokenUtility.communityInvolvement.description')
  },
  {
    heading: i18next.t('tokenUtility.reputationLevels'),
    text: i18next.t('tokenUtility.reputationLevels.description')
  },
  {
    heading: i18next.t('tokenUtility.promotionalUse'),
    text: i18next.t('tokenUtility.promotionalUse.description')
  },
  {
    heading: i18next.t('tokenUtility.feedbackPrograms'),
    text: i18next.t('tokenUtility.feedbackPrograms.description')
  }
]
