export function Waves() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="563"
      height="139"
      viewBox="0 0 563 139"
      fill="none"
    >
      <g opacity="0.3">
        <path
          d="M370.76 50.6711L395.615 51.3347C387.34 51.0427 379.035 50.8304 370.76 50.6711Z"
          fill="white"
        />
        <path
          d="M563 0.0530575H13.2946C15.2417 1.77837 20.4135 3.10559 24.6727 3.42411L132.977 11.7852C98.3257 14.8377 63.7048 17.8901 29.0535 20.9426C70.3674 21.4204 111.681 21.8982 152.995 22.3494C102.007 25.3754 51.0186 28.4013 0 31.4273C75.3567 31.9581 150.713 32.4624 226.07 32.9933C175.782 36.2581 125.463 39.5495 75.1741 42.8144C117.462 43.0002 159.749 43.186 202.067 43.3718C167.385 47.7249 132.703 52.0514 97.9911 56.4045C143.99 58.4484 189.958 60.4922 235.957 62.536C196.134 63.9694 156.555 67.3669 117.827 72.649C170.184 73.4453 222.511 74.2682 274.868 75.0645C240.004 79.4176 205.139 83.7707 170.275 88.0972C248.187 92.3176 327.073 91.2293 404.498 84.859C363.884 83.4787 323.24 82.0985 282.626 80.7182C308.698 78.0108 334.77 75.3034 360.842 72.5694C330.054 71.6138 299.297 70.6317 268.51 69.6762C318.646 65.2965 368.813 60.9169 418.949 56.5107C354.818 55.6879 290.657 54.8915 226.526 54.0687C274.32 50.8039 322.57 49.6625 370.699 50.6181C326.343 49.4237 281.987 48.2026 237.631 47.0082C329.598 43.4779 421.535 39.9211 513.502 36.3909C422.782 33.4976 332.032 30.6044 241.312 27.7377C303.374 26.2513 365.436 24.7914 427.498 23.305C358.591 22.1106 289.684 20.9161 220.807 19.7217C318.828 18.5272 416.88 17.3063 514.902 16.1118C431.362 14.8643 347.852 13.6167 264.311 12.3426C354.392 10.1661 444.443 7.98954 534.524 5.81299C544.898 5.5741 557.676 4.19384 563 0V0.0530575Z"
          fill="white"
        />
        <path
          d="M438.207 101.9C354.453 106.439 270.213 111.004 186.551 105.483C193.883 105.961 203.709 103.227 211.315 102.829C217.826 102.51 224.336 102.271 230.877 102.059C243.046 101.687 255.215 101.794 267.353 101.794L376.145 101.847H438.207V101.9Z"
          fill="white"
        />
        <path
          d="M327.103 116.207C268.935 120.639 210.311 121.913 151.9 119.949C180.649 117.56 209.033 114.455 237.905 114.057C253.055 113.844 268.205 113.791 283.325 114.11C297.867 114.402 312.622 116.207 327.103 116.207Z"
          fill="white"
        />
        <path
          d="M328.199 129.983C284.421 130.991 240.612 132.026 196.834 133.035C216.578 132.557 235.106 126.134 254.85 125.391C279.431 124.462 304.195 126.001 328.199 129.983Z"
          fill="white"
        />
        <path
          d="M309.245 136.618C283.964 139.034 258.348 139.618 232.915 138.344C235.075 138.45 237.844 137.707 240.064 137.494C242.65 137.282 245.236 137.123 247.822 137.07C251.716 136.99 255.519 136.91 259.383 136.725C269.97 136.194 280.679 136.3 291.266 136.353C297.259 136.379 303.252 136.459 309.245 136.618Z"
          fill="white"
        />
      </g>
    </svg>
  );
}