import { User } from 'types/User';
import { create } from 'zustand';

interface UserStore {
  isLoading: boolean;
  user: User;
  canEnterRefCode: boolean;
  setUser: (user: User) => void;
  setIsLoading: (isLoading: boolean) => void;
  setCanEnterRefCode: (canEnterRefCode: boolean) => void;
}

export const userStore = create<UserStore>((set) => ({
  isLoading: true,
  user: {
    id: '',
    email: null,
    createdAt: '',
    referralCode: undefined,
    bonusForInvitedPercent: undefined,
    kicked: false,
  },
  canEnterRefCode: true,
  setUser: (user) => set({ user }),
  setIsLoading: (isLoading) => set({ isLoading }),
  setCanEnterRefCode: (canEnterRefCode) => set({ canEnterRefCode }),
}));

export const useUserStore = userStore;
