import { InputHTMLAttributes, forwardRef } from "react";

export const FormTextArea = forwardRef<
  HTMLTextAreaElement,
  InputHTMLAttributes<HTMLTextAreaElement>
>((props, ref) => {
  return (
    <textarea
      className="h-[11.125rem] px-6 py-4 rounded-lg bg-white bg-opacity-5 outline-none font-medium resize-none placeholder:text-white"
      ref={ref}
      {...props}
    ></textarea>
  );
});
