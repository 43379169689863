import { ReactNode } from 'react';
import i18n from 'i18n';

interface RoadmapData {
  sectionName: string;
  accordions: {
    title: string;
    content: ReactNode;
  }[];
}

export const roadmapMockData = ():RoadmapData[] => [
  {
    sectionName: i18n.t('roadmap.initiating'),
    accordions: [
      {
        title: i18n.t('roadmap.establishmentCommunity'),
        content: (
          <ul className='list-disc pl-7'>
            <li>
              {i18n.t('roadmap.establishmentCommunity.description')}
            </li>
          </ul>
        ),
      },
      {
        title: i18n.t('roadmap.creationInfrastructure'),
        content: (
          <ul className='list-disc pl-7'>
            <li>
              {i18n.t('roadmap.creationInfrastructure.description')}
            </li>
          </ul>
        ),
      },
      {
        title: i18n.t('roadmap.tokensAudit'),
        content: (
          <ul className='list-disc pl-7'>
            <li>
              {i18n.t('roadmap.tokensAudit.description')}
            </li>
          </ul>
        ),
      },
      {
        title: i18n.t('roadmap.introductionCampaign'),
        content: (
          <ul className='list-disc pl-7'>
            <li>
              {i18n.t('roadmap.introductionCampaign.description')}
            </li>
          </ul>
        ),
      },
      {
        title: i18n.t('roadmap.earlyAccess'),
        content: (
          <ul className='list-disc pl-7'>
            <li>{i18n.t('roadmap.earlyAccess.description')}</li>
          </ul>
        ),
      },
    ],
  },
  {
    sectionName: i18n.t('roadmap.expandingHorizons'),
    accordions: [
      {
        title: i18n.t('roadmap.presale'),
        content: (
          <ul className='list-disc pl-7'>
            <li>{i18n.t('roadmap.presale.description')}</li>
          </ul>
        ),
      },
      {
        title: i18n.t('roadmap.tokenEconomy'),
        content: (
          <ul className='list-disc pl-7'>
            <li>
              {i18n.t('roadmap.tokenEconomy.description')}
            </li>
          </ul>
        ),
      },
      {
        title: i18n.t('roadmap.mvpDevelopment'),
        content: (
          <ul className='list-disc pl-7'>
            <li>{i18n.t('roadmap.mvpDevelopment.description')}</li>
          </ul>
        ),
      },
      {
        title: i18n.t('roadmap.pairEstablishment'),
        content: (
          <ul className='list-disc pl-7'>
            <li>
              {i18n.t('roadmap.pairEstablishment.description')}
            </li>
          </ul>
        ),
      },
      {
        title: i18n.t('roadmap.promotionalEfforts'),
        content: (
          <ul className='list-disc pl-7'>
            <li>
              {i18n.t('roadmap.promotionalEfforts.description')}
            </li>
          </ul>
        ),
      },
    ],
  },
  {
    sectionName: i18n.t('roadmap.buildingPlatform'),
    accordions: [
      {
        title: i18n.t('roadmap.managementEvents'),
        content: (
          <ul className='list-disc pl-7'>
            <li>
              {i18n.t('roadmap.managementEvents.descriptions')}
            </li>
          </ul>
        ),
      },
      {
        title: i18n.t('roadmap.releaseInitialMvp'),
        content: (
          <ul className='list-disc pl-7'>
            <li>
              {i18n.t('roadmap.releaseInitialMvp.descriptions')}
            </li>
          </ul>
        ),
      },
      {
        title: i18n.t('roadmap.promotionFunctionalities'),
        content: (
          <ul className='list-disc pl-7'>
            <li>
              {i18n.t('roadmap.promotionFunctionalities.descriptions')}
            </li>
          </ul>
        ),
      },
      {
        title: i18n.t('roadmap.finalizeMvp'),
        content: (
          <ul className='list-disc pl-7'>
            <li>
              {i18n.t('roadmap.finalizeMvp.descriptions')}
            </li>
          </ul>
        ),
      },
    ],
  },
  {
    sectionName: i18n.t('roadmap.refiningIntegrating'),
    accordions: [
      {
        title: i18n.t('roadmap.advancedSmartContract'),
        content: (
          <ul className='list-disc pl-7'>
            <li>
              {i18n.t('roadmap.advancedSmartContract.description')}
            </li>
          </ul>
        ),
      },
      {
        title: i18n.t('roadmap.inclusionCryptoIndexes'),
        content: (
          <ul className='list-disc pl-7'>
            <li>
              {i18n.t('roadmap.inclusionCryptoIndexes.description')}
            </li>
          </ul>
        ),
      },
      {
        title: i18n.t('roadmap.rewardsAdopters'),
        content: (
          <ul className='list-disc pl-7'>
            <li>
              {i18n.t('roadmap.rewardsAdopters.description')}
            </li>
          </ul>
        ),
      },
      {
        title: i18n.t('roadmap.applyCentralizedExchange'),
        content: (
          <ul className='list-disc pl-7'>
            <li>
              {i18n.t('roadmap.applyCentralizedExchange.description')}
            </li>
          </ul>
        ),
      },
      {
        title: i18n.t('roadmap.grandRelease'),
        content: (
          <ul className='list-disc pl-7'>
            <li>{i18n.t('roadmap.grandRelease.description')}</li>
          </ul>
        ),
      },
    ],
  },
];
