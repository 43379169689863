import clsx from "clsx";
import { ButtonHTMLAttributes, ReactNode, forwardRef } from "react";
import { variants } from "./variants";
import "./styles.css";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  variant?: keyof typeof variants;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, className, variant = "DEFAULT", ...props }, ref) => {
    return (
      <button
        className={clsx(
          "flex justify-center items-center gap-2.5 px-6 py-3 rounded-[0.625rem] font-bold text-sm leading-4",
          variants[variant],
          className
        )}
        ref={ref}
        {...props}
      >
        {children}
      </button>
    );
  }
);
