import { useAccount, useSignMessage } from 'wagmi';
import { DarkBg } from './components/DarkBg';
import { FAQ } from './components/FAQ';
import { Features } from './components/Features';
import { Footer } from './components/Footer';
import { Hero } from './components/Hero';
import { Roadmap } from './components/Roadmap';
import { Tokenomics } from './components/Tokenomics';
import { useLayoutEffect } from 'react';
import { authApi } from 'services/auth';
import { TokenUtility } from './components/TokenUtility';
import { FeedbackForm } from './components/FeedbackForm';
import Marquee from 'react-fast-marquee';
import { marqueeContentMap } from './components/Hero/marqueeContent';
import { SignMessageData } from 'wagmi/query';
import { wagmiConfig } from 'configurations/wagmi';
import { useTranslation } from 'react-i18next';

export function LandingView() {
  const { address } = useAccount();
  const { t } = useTranslation()

	const { signMessage } = useSignMessage({
    config: wagmiConfig,
    mutation: {
      onSuccess: (data: SignMessageData) => {
        if (!address) return;
        authApi.auth(address, data);
      },
    },
  });

  useLayoutEffect(() => {
    if (!address) return;

    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      signMessage({ message: 'Sign to verify ownership', account: address });
      return;
    }

    authApi.refresh(address).catch(() => {
      signMessage({ message: 'Sign to verify ownership', account: address });
    });
  }, [address]);

  return (
    <>
      <Hero  />

      <Marquee className='marquee py-11'>
        {marqueeContentMap.map((el, i) => (
          <div
            key={i}
            className='flex items-center gap-4 mr-14 text-xl leading-snug font-medium text-[#49122D]'
          >
            <img
              src={el.iconSrc}
              className='w-[2.625rem] h-[2.625rem]'
              alt=''
            />
            {el.text}
          </div>
        ))}
      </Marquee>

      <div className='max-w-[1440px] mx-auto px-5'>
        <div className='flex flex-col gap-2 mb-[6.25rem] px-[5.4375rem] lg:px-4 font-medium text-[2.625rem] md:text-[1.625rem] text-black'>
          <p className='flex flex-wrap items-center gap-3.5'>
            {t('main.title.firstPart')}{' '}
            <mark className='inline-flex justify-center items-center px-6 py-3 rounded-full bg-[#FFA28F] font-medium text-[1.5rem] leading-8 text-white'>
              {t('main.vacationMark')}
            </mark>{' '}
          </p>
          <p className='flex flex-wrap items-center gap-3.5'>
            {t('main.and')}{' '}
            <mark className='inline-flex justify-center items-center px-6 py-3 rounded-full bg-[#18BCBB] font-medium text-[1.5rem] leading-8 text-white'>
              {t('main.leasingMark')}
            </mark>
          </p>
          <p>
            {t('main.title.secondPart')}
          </p>
          <p className='flex flex-wrap items-center gap-4'>
            {t('main.title.thirdPart')}
          </p>
        </div>
        <Features />
        <TokenUtility />
        <Tokenomics />
        <div>
          <DarkBg />
          <Roadmap />
        </div>
        <FAQ />
        <FeedbackForm />
        <div className='text-center mb-6'>{t('disclaimer')}</div>
        <Footer />
      </div>
    </>
  );
}
