import { DialogClose } from '@radix-ui/react-dialog';
import SuccessIcon from 'assets/success.webp';
import { useTranslation } from 'react-i18next';
import { Button } from 'ui-kit/Button';

export function SuccessResult() {
  const { t } = useTranslation()

  return (
    <>
      <div className='flex justify-center'>
        <img
          src={SuccessIcon}
          className='w-[69px] h-[79px]'
          alt='Success icon'
        />
      </div>
      <div className='flex flex-col items-center gap-2'>
        <h2 className='font-medium text-2xl leading-snug text-[#512248] text-center'>
          {t('successResult')}
        </h2>
      </div>
      <DialogClose asChild>
        <Button className='h-[3.375rem]' variant='primary'>
          {t('backMainScreen')}
        </Button>
      </DialogClose>
    </>
  );
}
