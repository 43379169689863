import { Root as AccordionRoot } from '@radix-ui/react-accordion';
import { FAQAccordion } from './FAQAccordion';
import { faqData } from './faq';
import { useTranslation } from 'react-i18next';

export function FAQ() {
  const faq = faqData()
  const { t } = useTranslation()

  return (
    <div
      className='relative flex flex-col items-center mb-40 sm:mb-20 text-[#512248]'
      id='faq'
    >
      <h2 className='mb-[3.37rem] text-center font-medium text-[3.375rem] sm:text-[3rem] leading-none tracking-[1px]'>
        {t('faq.title')}
      </h2>
      <AccordionRoot
        type='single'
        collapsible
        className='flex flex-col w-full max-w-[800px] gap-4'
      >
        {faq.map(({ title, content }, i) => (
          <FAQAccordion key={`faq-${i}`} title={title} value={`faq-${i}`}>
            {content}
          </FAQAccordion>
        ))}
      </AccordionRoot>
    </div>
  );
}
