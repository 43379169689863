import { SVGAttributes } from "react";

export function Wallet({
  color = "black",
  ...props
}: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
    >
      <path
        d="M14.5625 16.5C15.2529 16.5 15.8125 15.9404 15.8125 15.25C15.8125 14.5596 15.2529 14 14.5625 14C13.8721 14 13.3125 14.5596 13.3125 15.25C13.3125 15.9404 13.8721 16.5 14.5625 16.5Z"
        fill={color}
      />
      <path
        d="M3.625 9.3125V19C3.625 19.3315 3.7567 19.6495 3.99112 19.8839C4.22554 20.1183 4.54348 20.25 4.875 20.25H17.375C17.5408 20.25 17.6997 20.1842 17.8169 20.0669C17.9342 19.9497 18 19.7908 18 19.625V11.5C18 11.3342 17.9342 11.1753 17.8169 11.0581C17.6997 10.9408 17.5408 10.875 17.375 10.875H5.22495C4.37771 10.875 3.65612 10.2162 3.62601 9.36948C3.61836 9.15963 3.65309 8.95039 3.72811 8.75426C3.80314 8.55814 3.91693 8.37915 4.06268 8.22798C4.20843 8.07681 4.38316 7.95658 4.57642 7.87445C4.76968 7.79233 4.97751 7.75 5.1875 7.75H15.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 2.08325V7.91659"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5833 5H23.4167"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
