import axios, { AxiosInstance } from 'axios';
import { API_URL } from 'constants/api';
import { mainStore } from 'store';
import { IPaymentLimit, Order, OrdersResponse } from 'types/Tokens';

export class TokensService {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: API_URL,
      withCredentials: true,
    });
  }

  async sendOrder(
    tokensAmount: number,
    paymentMethod: string,
    referralCode: string | undefined
  ): Promise<OrdersResponse | null> {
    try {
      const accessToken = localStorage.getItem('accessToken');

      if (!accessToken) {
        throw new Error('No access token');
      }

      const { currentRound } = mainStore.getState();

      if (!currentRound) return null;

      const { data } = await this._api.post<OrdersResponse>(
        '/api/orders',
        { tokensAmount, paymentMethod, roundId: currentRound.id, referralCode },
        {
          headers: {
            authorization: accessToken,
          },
        }
      );

      return data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getOrderById(orderId: string): Promise<Order | null> {
    try {
      const accessToken = localStorage.getItem('accessToken');

      if (!accessToken) {
        throw new Error('No access token');
      }

      const { data } = await this._api.get<Order>(`/api/orders/${orderId}`, {
        headers: {
          authorization: accessToken,
        },
      });

      return data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getTokensBalance(): Promise<number> {
    try {
      const url = '/api/balances/my-tokens';

      const accessToken = localStorage.getItem('accessToken');

      if (!accessToken) {
        throw new Error('No access token');
      }

      const { data } = await this._api.get<{ amount: number }>(url, {
        headers: {
          authorization: accessToken,
        },
      });

      return data.amount;
    } catch (error) {
      console.warn(error);
    }
    return 0;
  }

  async getMarkleTreeProof(): Promise<string[] | null> {
    const url = '/api/merkle-tree/proof';
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      throw new Error('No access token');
    }

    const { data } = await this._api.get<string[]>(url, {
      headers: {
        authorization: accessToken,
      },
    });

    return data;
  }

  async getPaymentLimit(token: string): Promise<IPaymentLimit> {
    const url = '/api/now-payments/limits';

    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      throw new Error('No access token');
    }

    const { data } = await this._api.get<IPaymentLimit>(url, {
      params: {
        token,
      },
      headers: {
        authorization: accessToken,
      },
    });

    return data;
  }
}

export const tokensApi = new TokensService();
