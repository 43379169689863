import i18n from 'i18n';

export const tokenomicsMockData = () => [
  {
    label: i18n.t('tokenomics.presaleAllocation'),
    value: 1250000000,
    color: '#D86B7D'
  },
  {
    label: i18n.t('tokenomics.developmentFunds'),
    value: 500000000,
    color: '#512248',
    border: true
  },
  {
    label: i18n.t('tokenomics.marketingInitiatives'),
    value: 375000000,
    color: '#FFE8DB'
  },
  {
    label: i18n.t('tokenomics.liquidityReserve'),
    value: 250000000,
    color: '#FFFFFF'
  },
  {
    label: i18n.t('tokenomics.communityRewards'),
    value: 125000000,
    color: '#FFC3C3'
  }
]
