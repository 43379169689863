import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@radix-ui/react-accordion';
import { AccordionTriggerIcon } from 'ui-kit/icons/AccordionTriggerIcon';
import { ReactNode } from 'react';
import './styles.css';

interface RoadmapAccordionProps {
  title: string;
  value: string;
  children: ReactNode;
}

export function RoadmapAccordion({
  title,
  value,
  children,
}: RoadmapAccordionProps) {
  return (
    <AccordionItem value={value}>
      <AccordionTrigger className="flex justify-between items-center w-full font-medium text-lg sm:text-sm leading-none tracking-[0.36px] text-start accordion-trigger">
        {title}
        <AccordionTriggerIcon
          fill="white"
          fillOpacity="0.2"
          className="min-h-[24px] min-w-[24px]"
        />
      </AccordionTrigger>
      <AccordionContent className="max-w-[634px] font-medium text-base sm:text-sm leading-[1.625rem] text-white text-opacity-70 accordion-animation">
        <div className="pt-4">{children}</div>
      </AccordionContent>
    </AccordionItem>
  );
}
