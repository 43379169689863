// import { useKittensStore } from "../../store/kittensStore"
import Markdown from "markdown-to-jsx";
import translateText from 'services/localization';
import { useEffect, useState } from "react";
import { useMainStore } from "store";
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

export const Banner = () => {
  const { banner } = useMainStore();
  const { i18n } = useTranslation();
  const language = i18n.language
  const [text, setText] = useState("");

  useEffect(() => {
    if (banner && banner.text) {
      translateText(banner.text, language).then((translatedText) =>
        setText(translatedText.replace("] (", "](").replace("&#39;", "`"))
      );
    }
  }, [language, banner]);


  if (!banner) {
    return null;
  }

  const BannerLink: React.FC<{ children: React.ReactNode }> = ({
    children,
    ...props
  }) => (
    <a target="_blanc" {...props}>
      {children}
    </a>
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 5 }}
      className= "relative z-[2] w-full h-[2.25rem] full-hd-bp:text-[1.33rem] lg:text-[0.6rem] flex justify-center items-center"
      style={{ backgroundColor: banner.backgroundColor }}
    >
      <p className='text-base' style={{ color: banner.textColor }}>
        <Markdown
          options={{
            overrides: {
              a: {
                component: BannerLink,
                props: {
                  className: "text-inherit underline"
                }
              }
            }
          }}
        >
          {text}
        </Markdown>
      </p>
    </motion.div>
  );
};
