import { useFormContext } from 'react-hook-form';
import { Button } from 'ui-kit/Button';
import { ErrorAlert } from 'ui-kit/ErrorAlert';
import { ConnectEmailForm } from './scheme';
import { Clear } from 'ui-kit/icons/Clear';
import { useTranslation } from 'react-i18next';

export function EmailStep() {
  const form = useFormContext<ConnectEmailForm>();
  const { t } = useTranslation()

  const email = form.watch('email');

  return (
    <>
      <h2 className="font-medium text-[1.75rem] leading-snug text-center">
        {t('emailStep.enterEmail')}
      </h2>
      <div className="flex flex-col gap-4">
        <p className="mx-auto font-normal text-sm leading-5 text-center text-[#978D95]">
          {t('emailStep.enterEmail.description')}
        </p>
        <div className="w-full flex flex-col gap-1">
          <label
            htmlFor="email"
            className="font-medium text-xs leading-5 text-[#978D95]"
          >
            {t('emailStep.emailAddress')}
          </label>
          <label className="flex h-[3.125rem] items-center gap-4 p-3.5 rounded-lg bg-[#F2F2F2] text-[#512248] cursor-text">
            <input
              type="email"
              className="grow bg-transparent font-medium text-base leading-snug outline-none hero-form-field text-[#512248]"
              placeholder="example@example.com"
              id="email"
              required
              {...form.register('email')}
            />
            {email.length > 0 && (
              <button type="button" onClick={() => form.setValue('email', '')}>
                <Clear />
              </button>
            )}
          </label>
        </div>
        {form.formState.errors.email && (
          <ErrorAlert>{form.formState.errors.email?.message}</ErrorAlert>
        )}
      </div>
      <Button
        type="submit"
        disabled={form.formState.isSubmitting}
        variant="primary"
        className="h-[3.125rem] transition-opacity duration-300"
      >
        {t('emailStep.confirm')}
      </Button>
    </>
  );
}
