import { useLayoutEffect, useRef } from "react";

export function DarkBg() {
  const ref = useRef<HTMLDivElement>(null);

  const handleResize = () => {
    const roadmap = document.getElementById("roadmap")?.getClientRects()?.[0];
    const current = ref.current?.getClientRects()?.[0];
    if (roadmap && current) {
      ref.current?.setAttribute(
        "style",
        `height: ${roadmap.bottom - current.y}px;`
      );
    }
  }

  useLayoutEffect(() => {
    const roadmap = document.getElementById('roadmap');

    if (!roadmap) return;

    const observer = new ResizeObserver(handleResize);

    observer.observe(roadmap);

    handleResize();

    return () => observer.disconnect();
  }, []);

  return (
    <div
      className="absolute left-0 right-0 -translate-y-[420px] rounded-t-3xl bg-[#25101A] z-[-1]"
      ref={ref}
    />
  );
}
