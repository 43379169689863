import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { useFormContext } from 'react-hook-form';
import { PaymentProgress } from 'ui-kit/PaymentProgress';
import { Copy } from 'ui-kit/icons/Copy';
import { Tick } from 'ui-kit/icons/Tick';
import { formatAddress } from 'utils';
import { PaymentFormType } from './scheme';
import { Button } from 'ui-kit/Button';
import { RightArrowWithTail } from 'ui-kit/icons/RightArrowWithTail';
import { QRCodeSVG } from 'qrcode.react';
import { useEffect } from 'react';
import { tokensApi } from 'services/tokens';
import { useUserStore } from 'store/user';
import { useTranslation } from 'react-i18next';

interface SendDepositProps {
  paymentInUSD: number;
}

export function SendDeposit({ paymentInUSD }: SendDepositProps) {
  const [canEnterRefCode] = useUserStore((state) => [state.canEnterRefCode]);
  const form = useFormContext<PaymentFormType>();
  const { t } = useTranslation()

  if (!form)
    throw new Error('This component must be used within a <FormProvider />');

  const orderId = form.watch('orderId');
  const paymentMethod = form.watch('paymentMethod');
  const paymentStatus = form.watch('paymentStatus');
  const paymentInAsset = form.watch('payAmount');
  const address = form.watch('payAddress');

  const [copiedText, copyToClipboard] = useCopyToClipboard(2000);
  const handleCopy = () => {
    copyToClipboard(address);
  };

  useEffect(() => {
    if (paymentStatus === 'PAID') return;

    const interval = setTimeout(async () => {
      if (!orderId) return;
      try {
        const order = await tokensApi.getOrderById(orderId);

        if (!order) return;

        if (order.status === 'EXPIRED') {
          form.setValue('step', 'error');
          return;
        }

        form.setValue('paymentStatus', order.status);
      } catch (error) {
        form.setValue('step', 'error');
      }
    }, 3000);

    return () => clearTimeout(interval);
  }, [orderId, form, paymentStatus]);

  const handleSendDeposit = () => {
    if (paymentStatus === 'PAID') {
      form.setValue('step', 'success');
    }
  };

  return (
    <>
      <div className='flex flex-col items-center gap-2'>
        <div className='my-auto px-3 py-1.5 rounded-lg bg-[#F9F1F5] font-medium text-xs leading-5 text-[#7F6C7B]'>
          {canEnterRefCode ? 3 : 2} {t('of')} {canEnterRefCode ? 3 : 2} {t('steps')}
        </div>
        <h2 className='font-medium text-2xl leading-snug text-[#512248]'>
          {t('sendDeposit')}
        </h2>
      </div>
      <div className='flex flex-col gap-4'>
        <div className='flex items-start gap-6'>
          <QRCodeSVG value={address} width={140} height={140} />
          <div className='flex flex-col gap-4'>
            <div className='flex flex-col gap-2 font-medium text-xs text-[#978D95]'>
              <span>{t('selectAsset')}</span>
              <div className='flex items-center gap-2'>
                <img
                  src={paymentMethod.icon}
                  className='w-6 h-6'
                  alt={paymentMethod.name}
                />
                <span className='font-bold text-sm leading-5 text-[#512248]'>
                  {paymentInAsset} {paymentMethod.currency}
                </span>
                <span>~ ${paymentInUSD || 0}</span>
              </div>
            </div>
            <div className='flex flex-col gap-2 font-medium text-xs text-[#978D95]'>
              <span>{t('address')}</span>
              <div className='flex items-center gap-2'>
                <span className='w-[150px] font-bold text-sm leading-5 text-[#512248]'>
                  {formatAddress(address, 4, 3)}
                </span>
                <button disabled={!!copiedText} onClick={handleCopy}>
                  {!!copiedText ? <Tick color='#512248' /> : <Copy />}
                </button>
              </div>
            </div>
          </div>
        </div>
        <PaymentProgress status={paymentStatus} />
      </div>
      <Button
        className='h-[3.375rem]'
        variant='primary'
        onClick={handleSendDeposit}
        disabled={paymentStatus !== 'PAID'}
      >
        {t('nextStep')}
        <RightArrowWithTail />
      </Button>
    </>
  );
}
