import clsx from "clsx";
import { ProgressIcon } from "./ProgressIcon";
import { OrderStatus } from "types/Tokens";
import { t } from "i18next";

export interface PaymentProgressProps {
  status: OrderStatus;
}

export function PaymentProgress({ status }: PaymentProgressProps) {
  return (
    <div className='flex px-[1.625rem] py-[1.125rem] gap-4 rounded-2xl bg-[#F9F1F5] font-medium text-sm text-[#B2A7B0]'>
      <ProgressIcon status={status} />
      <div className='flex flex-col justify-between leading-none pt-3'>
        <div className='font-bold text-[#512248]'>
          {t("paymentProgress.firstStep")}
        </div>
        <div
          className={clsx(
            status === "PAID" && "font-bold text-[#512248]",
            " transition-colors duration-200"
          )}
        >
          {t("paymentProgress.thirdStep")}
        </div>
      </div>
    </div>
  );
}
