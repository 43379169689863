import { SVGAttributes } from "react";

export function Logo(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      {...props}
    >
      <path
        d="M12.5 25.8571C5.8837 25.8571 0.5 20.6598 0.5 14.2725C0.5 11.622 1.43638 9.07318 3.15209 7.01573L1.0825 6.01388L1.39265 4.39018L4.97714 5.25385C5.24553 5.04465 5.50795 4.85464 5.77236 4.68191C6.02485 4.51685 6.31908 4.42857 6.62326 4.42857C6.98509 4.42857 7.333 4.5514 7.60736 4.76636C9.12426 4.06583 10.8022 3.69925 12.4861 3.69925C12.7823 3.69925 13.0905 3.71268 13.4245 3.73955C13.9115 3.49389 14.3926 3.33651 14.8062 3.23479C14.7207 2.54961 14.7286 1.68594 15.0288 0.80116L15.2137 0.25417L17.2753 0.9048C17.492 0.728228 17.7286 0.56701 17.9871 0.426904L18.506 0.142853L19.5934 1.98918L19.0746 2.27323C19.0229 2.30202 18.9732 2.33081 18.9254 2.36152C19.2833 2.29243 19.6491 2.25788 20.0149 2.25788C20.2555 2.25788 20.5 2.27323 20.7445 2.30202L21.337 2.37304L21.0646 4.48039L20.4722 4.40937C20.3191 4.3921 20.166 4.3825 20.0169 4.3825C19.6312 4.3825 19.2873 4.44392 18.9871 4.53413C19.9473 5.13294 20.826 5.86226 21.5636 6.6837C23.4563 8.78913 24.5 11.4838 24.5 14.2763C24.5 20.6598 19.1183 25.8571 12.5 25.8571Z"
        fill="#D1D3D4"
      />
      <path
        d="M12.4861 4.27307C8.99108 4.27307 5.88969 5.88525 3.92548 8.37837H21.0487C19.0805 5.88525 15.9811 4.27307 12.4861 4.27307Z"
        fill="#FAD1D1"
      />
      <path
        d="M21.0467 8.37839H3.92347C3.74852 8.60102 3.57953 8.82749 3.42248 9.06356C2.89763 9.85238 2.48411 10.7141 2.19385 11.6296H22.7764C22.4861 10.7141 22.0726 9.85238 21.5477 9.06356C21.3907 8.82749 21.2217 8.60102 21.0467 8.37839Z"
        fill="#FFB3D2"
      />
      <path
        d="M2.19386 11.6296C1.88968 12.5893 1.72467 13.6046 1.72467 14.6602C1.72467 14.735 1.72865 14.8079 1.73063 14.8809H23.2416C23.2436 14.8079 23.2475 14.7331 23.2475 14.6602C23.2475 13.6046 23.0825 12.5874 22.7783 11.6296H2.19386Z"
        fill="#FD77AB"
      />
      <path
        d="M1.73065 14.8809C1.7565 16.0228 1.96922 17.1187 2.34695 18.1398H22.6253C23.001 17.1168 23.2157 16.0209 23.2416 14.8809H1.73065Z"
        fill="#18BCBB"
      />
      <path
        d="M12.4861 25.0472C15.832 25.0472 18.8121 23.5655 20.7843 21.2528H4.18787C6.16003 23.5674 9.14015 25.0472 12.4861 25.0472Z"
        fill="#89E6E7"
      />
      <path
        d="M22.6232 18.1417H2.34491C2.65902 18.9958 3.09242 19.7923 3.6133 20.5254C3.79222 20.7768 3.98507 21.0167 4.18586 21.2528H20.7823C20.9831 21.0187 21.1759 20.7768 21.3548 20.5254C21.8777 19.7923 22.3091 18.9938 22.6232 18.1417Z"
        fill="#4AD8D4"
      />
      <path
        d="M12.5 25.2794C6.21175 25.2794 1.09644 20.3412 1.09644 14.2705C1.09644 10.6009 2.99107 7.1923 6.10439 5.15404C6.56562 4.85272 7.19982 5.01393 7.47019 5.48415C3.62725 7.53968 1.47417 11.7448 2.26741 16.0478C3.12824 20.7231 7.38471 24.2142 12.3032 24.3025C18.1243 24.4062 22.8937 19.8652 22.8937 14.2686C22.8937 11.8503 21.9911 9.51652 20.3509 7.69322C19.3251 6.55126 18.0249 5.63577 16.5915 5.04272L16.9891 4.14835C18.5597 4.79898 19.9871 5.80467 21.1123 7.05603C22.9115 9.0559 23.9016 11.6181 23.9016 14.2705C23.9036 20.3412 18.7883 25.2794 12.5 25.2794Z"
        fill="#1E2628"
      />
      <path
        d="M23.1879 14.8809H1.81213V15.8539H23.1879V14.8809Z"
        fill="#1E2628"
      />
      <path
        d="M10.6253 18.1724H2.59344V19.1455H10.6253V18.1724Z"
        fill="#1E2628"
      />
      <path
        d="M20.2893 21.2931H11.8201V22.2662H20.2893V21.2931Z"
        fill="#1E2628"
      />
      <path
        d="M19.3985 18.1724H14.3906V19.1455H19.3985V18.1724Z"
        fill="#1E2628"
      />
      <g opacity="0.15">
        <path
          d="M12.5 7.40149C10.6014 7.40149 9.05664 8.89276 9.05664 10.7257C9.05664 12.5585 10.6014 14.0498 12.5 14.0498C14.3986 14.0498 15.9433 12.5585 15.9433 10.7257C15.9433 8.89276 14.3986 7.40149 12.5 7.40149Z"
          fill="#1E2628"
        />
      </g>
      <path
        d="M23.1899 11.5855H18.1819V12.5585H23.1899V11.5855Z"
        fill="#1E2628"
      />
      <path
        d="M7.83795 12.071H1.66101V13.0441H7.83795V12.071Z"
        fill="#1E2628"
      />
      <path
        d="M11.6869 6.50519H8.26141V7.47825H11.6869V6.50519Z"
        fill="#1E2628"
      />
      <path
        d="M5.51394 5.97357L1.86384 5.09454L1.74854 5.69143L4.18392 6.86986"
        fill="#1E2628"
      />
      <path
        d="M5.6372 6.0791L6.65709 9.57408L6.04277 9.70267L4.74854 7.38996"
        fill="#1E2628"
      />
      <path
        d="M14.8061 7.33813L13.8101 7.19034C14.1123 5.29795 15.5855 4.51105 16.3111 4.23276C16.3211 3.48041 16.5576 1.87015 18.2813 0.92395L18.7803 1.77035C17.1203 2.68199 17.3211 4.44388 17.331 4.51873L17.3847 4.94864L16.9473 5.05228C16.8737 5.06956 15.0964 5.51483 14.8061 7.33813Z"
        fill="#1E2628"
      />
      <path
        d="M17.2017 4.90645L16.4562 4.25006C16.5218 4.18097 18.0804 2.55727 20.6709 2.86819L20.5456 3.83358C18.49 3.58792 17.2534 4.85271 17.2017 4.90645Z"
        fill="#1E2628"
      />
      <path
        d="M16.5457 8.65285C15.3211 6.42843 16.337 4.44199 16.3807 4.35754L17.2793 4.79706L16.83 4.57634L17.2813 4.79514C17.2475 4.86039 16.4642 6.42843 17.4364 8.19607L16.5457 8.65285Z"
        fill="#1E2628"
      />
      <path
        d="M13.5378 5.50523L12.9036 4.74904C13.7982 4.05043 14.836 3.78557 15.4861 3.68577C15.3509 3.06393 15.2376 2.03329 15.5954 0.977692L16.5537 1.27902C16.1083 2.59755 16.5954 3.92952 16.5994 3.94295L16.8399 4.58591L16.1322 4.5955C16.1183 4.59742 14.6551 4.63197 13.5378 5.50523Z"
        fill="#1E2628"
      />
      <path
        d="M12.5 13.2514C11.0586 13.2514 9.88568 12.119 9.88568 10.7276C9.88568 9.3361 11.0586 8.20374 12.5 8.20374C13.9413 8.20374 15.1143 9.3361 15.1143 10.7276C15.1163 12.119 13.9413 13.2514 12.5 13.2514ZM12.5 9.17488C11.6133 9.17488 10.8936 9.87158 10.8936 10.7256C10.8936 11.5816 11.6153 12.2764 12.5 12.2764C13.3867 12.2764 14.1064 11.5797 14.1064 10.7256C14.1083 9.87158 13.3867 9.17488 12.5 9.17488Z"
        fill="#1E2628"
      />
      <path
        d="M12.5 9.1749C11.6133 9.1749 10.8936 9.87159 10.8936 10.7257C10.8936 11.5817 11.6153 12.2764 12.5 12.2764C13.3867 12.2764 14.1063 11.5797 14.1063 10.7257C14.1083 9.87159 13.3867 9.1749 12.5 9.1749Z"
        fill="#FBB040"
      />
    </svg>
  );
}