import { SVGAttributes } from "react";

export function InfoWithGradient(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      {...props}
    >
      <g id="info">
        <path
          id="Vector"
          d="M6.99984 13.3334C10.2215 13.3334 12.8332 10.7217 12.8332 7.50008C12.8332 4.27842 10.2215 1.66675 6.99984 1.66675C3.77818 1.66675 1.1665 4.27842 1.1665 7.50008C1.1665 10.7217 3.77818 13.3334 6.99984 13.3334Z"
          fill="url(#paint0_linear_642_687)"
        />
        <path
          id="Vector_2"
          d="M7 9.83333V7.5"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M7 5.16675H7.00583"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_642_687"
          x1="3.5"
          y1="3"
          x2="6.99984"
          y2="13.3334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#512248" />
          <stop offset="1" stopColor="#DA4D6F" />
        </linearGradient>
      </defs>
    </svg>
  );
}