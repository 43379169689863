import { SVGAttributes } from 'react';

export function LogoWithText(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='200'
      height='47'
      viewBox='0 0 200 47'
      fill='none'
      className='sm:h-[30px]'
      {...props}
    >
      <path
        d='M20.8029 46.1759C9.33306 46.1759 0 36.8428 0 25.3729C0 20.6134 1.62329 16.0364 4.5976 12.3418L1.00982 10.5428L1.54747 7.62705L7.76146 9.17796C8.22673 8.80229 8.68167 8.46109 9.14005 8.15091C9.57775 7.85452 10.0878 7.69598 10.6151 7.69598C11.2424 7.69598 11.8455 7.91655 12.3211 8.30256C14.9508 7.04459 17.8596 6.38632 20.7788 6.38632C21.2923 6.38632 21.8265 6.41044 22.4055 6.45869C23.2499 6.01754 24.084 5.73493 24.8008 5.55227C24.6526 4.32188 24.6664 2.77097 25.1868 1.18214L25.5074 0.199895L29.0813 1.36825C29.457 1.05117 29.8671 0.761671 30.3152 0.510078L31.2147 0L33.0999 3.31551L32.2004 3.82559C32.1108 3.87728 32.0246 3.92898 31.9419 3.98412C32.5623 3.86005 33.1964 3.79801 33.8306 3.79801C34.2476 3.79801 34.6715 3.82559 35.0954 3.87728L36.1225 4.0048L35.6503 7.78903L34.6233 7.66151C34.3579 7.63049 34.0925 7.61326 33.834 7.61326C33.1654 7.61326 32.5692 7.72355 32.0488 7.88553C33.7134 8.96083 35.2368 10.2705 36.5154 11.7456C39.7964 15.5264 41.6058 20.3652 41.6058 25.3798C41.6058 36.8428 32.2762 46.1759 20.8029 46.1759Z'
        fill='#D1D3D4'
      />
      <path
        d='M20.7788 7.41675C14.7198 7.41675 9.34335 10.3118 5.93823 14.7888H35.6227C32.2107 10.3118 26.8377 7.41675 20.7788 7.41675Z'
        fill='#FAD1D1'
      />
      <path
        d='M35.6193 14.7888H5.93484C5.63155 15.1886 5.33859 15.5953 5.06632 16.0192C4.15645 17.4357 3.43959 18.9832 2.9364 20.6271H38.6177C38.1146 18.9832 37.3977 17.4357 36.4878 16.0192C36.2156 15.5953 35.9226 15.1886 35.6193 14.7888Z'
        fill='#FFB3D2'
      />
      <path
        d='M2.93641 20.6272C2.4091 22.3504 2.12305 24.1736 2.12305 26.0692C2.12305 26.2036 2.12994 26.3346 2.13339 26.4655H39.4242C39.4277 26.3346 39.4346 26.2001 39.4346 26.0692C39.4346 24.1736 39.1485 22.347 38.6212 20.6272H2.93641Z'
        fill='#FD77AB'
      />
      <path
        d='M2.13342 26.4653C2.17823 28.516 2.547 30.4839 3.20183 32.3174H38.3559C39.0072 30.4805 39.3795 28.5125 39.4243 26.4653H2.13342Z'
        fill='#18BCBB'
      />
      <path
        d='M20.7788 44.7214C26.5792 44.7214 31.7455 42.0607 35.1644 37.9077H6.39319C9.81209 42.0642 14.9784 44.7214 20.7788 44.7214Z'
        fill='#89E6E7'
      />
      <path
        d='M38.3524 32.321H3.19836C3.74291 33.8547 4.49424 35.285 5.39721 36.6016C5.7074 37.0531 6.04171 37.4839 6.3898 37.9078H35.161C35.5091 37.4873 35.8434 37.0531 36.1535 36.6016C37.06 35.285 37.8079 33.8513 38.3524 32.321Z'
        fill='#4AD8D4'
      />
      <path
        d='M20.8029 45.1383C9.90172 45.1383 1.03394 36.2705 1.03394 25.3693C1.03394 18.7797 4.31842 12.6587 9.7156 8.99858C10.5152 8.45748 11.6146 8.74699 12.0833 9.59137C5.4213 13.2825 1.68877 20.8338 3.06391 28.5608C4.55623 36.9564 11.9351 43.2255 20.4617 43.3841C30.553 43.5702 38.8211 35.4158 38.8211 25.3659C38.8211 21.0233 37.2564 16.8324 34.413 13.5583C32.6347 11.5076 30.3807 9.86364 27.8958 8.79868L28.5851 7.19263C31.3078 8.36098 33.7823 10.1669 35.733 12.414C38.8521 16.0053 40.5684 20.6063 40.5684 25.3693C40.5719 36.2705 31.7041 45.1383 20.8029 45.1383Z'
        fill='#1E2628'
      />
      <path
        d='M39.3312 26.4653H2.27466V28.2127H39.3312V26.4653Z'
        fill='#1E2628'
      />
      <path
        d='M17.5529 32.376H3.62915V34.1233H17.5529V32.376Z'
        fill='#1E2628'
      />
      <path d='M34.3062 37.98H19.6243V39.7273H34.3062V37.98Z' fill='#1E2628' />
      <path
        d='M32.7622 32.376H24.0806V34.1233H32.7622V32.376Z'
        fill='#1E2628'
      />
      <g opacity='0.15'>
        <path
          d='M20.8029 13.0344C17.5115 13.0344 14.8336 15.7123 14.8336 19.0037C14.8336 22.2951 17.5115 24.973 20.8029 24.973C24.0943 24.973 26.7722 22.2951 26.7722 19.0037C26.7722 15.7123 24.0943 13.0344 20.8029 13.0344Z'
          fill='#1E2628'
        />
      </g>
      <path
        d='M39.3346 20.5479H30.653V22.2952H39.3346V20.5479Z'
        fill='#1E2628'
      />
      <path
        d='M12.7209 21.4197H2.0127V23.167H12.7209V21.4197Z'
        fill='#1E2628'
      />
      <path
        d='M19.3934 11.425H13.4551V13.1724H19.3934V11.425Z'
        fill='#1E2628'
      />
      <path
        d='M8.69205 10.4703L2.36432 8.89185L2.16443 9.9637L6.38636 12.0798'
        fill='#1E2628'
      />
      <path
        d='M8.90569 10.6599L10.6737 16.9359L9.60877 17.1669L7.36511 13.0139'
        fill='#1E2628'
      />
      <path
        d='M24.8008 12.9207L23.0741 12.6553C23.598 9.2571 26.1518 7.84405 27.4098 7.34431C27.427 5.99329 27.8371 3.1017 30.8252 1.40259L31.6903 2.92248C28.8125 4.55956 29.1606 7.72342 29.1778 7.85783L29.2709 8.62984L28.5126 8.81595C28.3851 8.84697 25.304 9.64655 24.8008 12.9207Z'
        fill='#1E2628'
      />
      <path
        d='M28.9538 8.55412L27.6614 7.37542C27.7751 7.25135 30.4771 4.33563 34.9679 4.89396L34.7508 6.62754C31.1871 6.18639 29.0434 8.45762 28.9538 8.55412Z'
        fill='#1E2628'
      />
      <path
        d='M27.8164 15.2816C25.6934 11.2871 27.4546 7.72 27.5304 7.56836L29.0882 8.3576L28.3093 7.96126L29.0916 8.35416C29.033 8.47134 27.6751 11.2871 29.3605 14.4613L27.8164 15.2816Z'
        fill='#1E2628'
      />
      <path
        d='M22.602 9.62926L21.5026 8.27135C23.0535 7.01683 24.8525 6.54122 25.9795 6.362C25.7452 5.24534 25.5487 3.39458 26.1691 1.49902L27.8303 2.04012C27.0583 4.40785 27.9027 6.7997 27.9096 6.82383L28.3266 7.9784L27.0996 7.99563C27.0755 7.99907 24.5389 8.06111 22.602 9.62926Z'
        fill='#1E2628'
      />
      <path
        d='M20.803 23.5393C18.3043 23.5393 16.2709 21.5059 16.2709 19.0072C16.2709 16.5085 18.3043 14.4751 20.803 14.4751C23.3017 14.4751 25.3351 16.5085 25.3351 19.0072C25.3385 21.5059 23.3017 23.5393 20.803 23.5393ZM20.803 16.219C19.2659 16.219 18.0182 17.4701 18.0182 19.0038C18.0182 20.5409 19.2693 21.7885 20.803 21.7885C22.3401 21.7885 23.5877 20.5374 23.5877 19.0038C23.5912 17.4701 22.3401 16.219 20.803 16.219Z'
        fill='#1E2628'
      />
      <path
        d='M20.8029 16.219C19.2658 16.219 18.0182 17.4701 18.0182 19.0037C18.0182 20.5409 19.2693 21.7885 20.8029 21.7885C22.3401 21.7885 23.5877 20.5374 23.5877 19.0037C23.5911 17.4701 22.3401 16.219 20.8029 16.219Z'
        fill='#FBB040'
      />
      <path
        d='M62.2629 19.345C63.2984 20.3365 63.8162 21.5625 63.8162 23.0279C63.8162 24.4932 63.2984 25.7241 62.2629 26.7108C61.2274 27.7023 59.9672 28.1956 58.4872 28.1956H54.692V33.002H51.8248V17.8601H58.4872C59.9672 17.8601 61.2274 18.3583 62.2629 19.345ZM60.1528 24.7472C60.6022 24.2783 60.8269 23.7068 60.8269 23.0328C60.8269 22.3587 60.6022 21.7872 60.1528 21.3183C59.7035 20.8494 59.1466 20.6101 58.4872 20.6101H54.692V25.4555H58.4872C59.1466 25.4555 59.7035 25.2161 60.1528 24.7472Z'
        fill='#512248'
      />
      <path
        d='M75.7293 24.9476V33.0021H72.8622V31.3072L72.8036 31.2877C71.9439 32.6602 70.7472 33.3489 69.2086 33.3489C66.1558 33.3489 64.627 32.0643 64.627 29.4951C64.627 28.313 65.0177 27.4045 65.7992 26.7695C66.5807 26.1394 67.7579 25.822 69.3307 25.822H72.8622V24.9525C72.8622 24.2931 72.6717 23.8535 72.2858 23.6288C71.8999 23.409 71.2991 23.2967 70.4785 23.2967C69.6726 23.2967 69.0669 23.409 68.6713 23.6386C68.2757 23.8682 68.0754 24.3029 68.0754 24.9525H65.3303C65.3303 23.6337 65.7895 22.6422 66.7029 21.973C67.6162 21.3087 68.8764 20.9717 70.4785 20.9717C72.0953 20.9717 73.3701 21.3038 74.3129 21.973C75.2556 22.6422 75.7293 23.6288 75.7293 24.9476ZM72.8622 28.904V28.0542H69.3307C68.1731 28.0542 67.5967 28.5035 67.5967 29.4071C67.5967 29.8907 67.7579 30.2375 68.0803 30.4475C68.4026 30.6576 68.9937 30.7601 69.8582 30.7601C70.7716 30.7601 71.5043 30.5843 72.0513 30.2229C72.5886 29.8712 72.8622 29.4316 72.8622 28.904Z'
        fill='#512248'
      />
      <path
        d='M78.1521 33.0021V21.3136H80.6969V22.9303H80.736C81.5028 21.6262 82.5677 20.9717 83.9255 20.9717H84.1698V23.8779H83.5055C82.8216 23.8779 82.2306 24.1124 81.7373 24.5764C81.244 25.0404 80.9753 25.6168 80.9216 26.3006V33.0021H78.1521Z'
        fill='#512248'
      />
      <path
        d='M96.0146 24.9476V33.0021H93.1474V31.3072L93.0888 31.2877C92.2291 32.6602 91.0324 33.3489 89.4938 33.3489C86.441 33.3489 84.9122 32.0643 84.9122 29.4951C84.9122 28.313 85.3029 27.4045 86.0844 26.7695C86.866 26.1394 88.0431 25.822 89.6159 25.822H93.1474V24.9525C93.1474 24.2931 92.9569 23.8535 92.571 23.6288C92.1851 23.4041 91.5843 23.2967 90.7638 23.2967C89.9578 23.2967 89.3521 23.409 88.9565 23.6386C88.5609 23.8682 88.3606 24.3029 88.3606 24.9525H85.6155C85.6155 23.6337 86.0747 22.6422 86.9881 21.973C87.9015 21.3087 89.1617 20.9717 90.7638 20.9717C92.3805 20.9717 93.6602 21.3038 94.5981 21.973C95.5457 22.6373 96.0146 23.6288 96.0146 24.9476ZM93.1474 28.904V28.0542H89.6159C88.4583 28.0542 87.8819 28.5035 87.8819 29.4071C87.8819 29.8907 88.0431 30.2375 88.3655 30.4475C88.6879 30.6576 89.2789 30.7601 90.1434 30.7601C91.0568 30.7601 91.7895 30.5843 92.3366 30.2229C92.8787 29.8712 93.1474 29.4316 93.1474 28.904Z'
        fill='#512248'
      />
      <path
        d='M107.845 33.002V31.2875H107.806C106.785 32.66 105.486 33.3488 103.908 33.3488C102.174 33.3488 100.757 32.7626 99.6682 31.5903C98.5789 30.4181 98.0319 28.9381 98.0319 27.1504C98.0319 25.3626 98.574 23.8827 99.6682 22.7202C100.762 21.5577 102.174 20.9715 103.908 20.9715C105.495 20.9715 106.795 21.6505 107.806 23.0083H107.845V17.8601H110.712V33.002H107.845ZM106.878 29.661C107.522 28.9527 107.845 28.1175 107.845 27.1455C107.845 26.1784 107.522 25.3431 106.878 24.6446C106.233 23.9462 105.417 23.5945 104.435 23.5945C103.454 23.5945 102.638 23.9462 101.993 24.6446C101.348 25.3431 101.026 26.1784 101.026 27.1455C101.026 28.1126 101.348 28.9527 101.993 29.661C102.638 30.3692 103.454 30.7209 104.435 30.7209C105.417 30.7209 106.228 30.3692 106.878 29.661Z'
        fill='#512248'
      />
      <path
        d='M112.973 19.096C112.973 18.6662 113.13 18.2949 113.447 17.9872C113.765 17.6795 114.136 17.5232 114.566 17.5232C114.996 17.5232 115.367 17.6795 115.684 17.9872C116.002 18.2949 116.158 18.6662 116.158 19.096C116.158 19.5405 116.002 19.9117 115.694 20.2145C115.386 20.5174 115.005 20.6688 114.566 20.6688C114.121 20.6688 113.745 20.5174 113.433 20.2145C113.13 19.9117 112.973 19.5405 112.973 19.096ZM113.135 21.3135H116.002V33.0021H113.135V21.3135Z'
        fill='#512248'
      />
      <path
        d='M127.852 24.9281H125.106C125.106 24.4006 124.916 23.9952 124.54 23.707C124.164 23.4188 123.656 23.2723 123.026 23.2723C122.42 23.2723 121.966 23.3895 121.663 23.6191C121.36 23.8486 121.209 24.1515 121.209 24.5178C121.209 24.8988 121.385 25.1919 121.736 25.397C122.088 25.6022 122.523 25.7438 123.035 25.8268C123.548 25.9099 124.11 26.0027 124.721 26.1004C125.331 26.1981 125.898 26.3446 126.411 26.5351C126.923 26.7256 127.358 27.087 127.71 27.6194C128.057 28.1518 128.233 28.8357 128.233 29.666C128.233 30.892 127.749 31.8103 126.787 32.4209C125.824 33.0314 124.569 33.3391 123.021 33.3391C121.502 33.3391 120.285 32.9777 119.377 32.2597C118.468 31.5417 118.014 30.5794 118.014 29.3827H120.779C120.779 29.8663 120.989 30.2619 121.404 30.5648C121.819 30.8676 122.361 31.019 123.021 31.019C123.71 31.019 124.257 30.9018 124.667 30.6673C125.077 30.4329 125.282 30.1007 125.282 29.6709C125.282 29.2557 125.111 28.9382 124.765 28.7233C124.423 28.5084 123.993 28.3765 123.47 28.3326C122.952 28.2837 122.391 28.2056 121.785 28.0883C121.179 27.9711 120.618 27.8246 120.1 27.639C119.582 27.4582 119.152 27.1017 118.806 26.579C118.464 26.0564 118.293 25.3726 118.293 24.5422C118.293 23.3846 118.708 22.5005 119.543 21.89C120.378 21.2794 121.536 20.9717 123.016 20.9717C124.525 20.9717 125.702 21.3185 126.557 22.0121C127.422 22.7057 127.852 23.6777 127.852 24.9281Z'
        fill='#512248'
      />
      <path
        d='M142.51 27.1505C142.51 27.5559 142.456 27.9907 142.349 28.4645H132.922C133.083 29.1239 133.469 29.6905 134.084 30.1594C134.695 30.6332 135.398 30.8676 136.194 30.8676C136.775 30.8676 137.293 30.7357 137.747 30.472C138.207 30.2082 138.563 29.8858 138.817 29.4951H141.929C141.513 30.6673 140.776 31.6003 139.731 32.3036C138.68 33.0021 137.503 33.3538 136.199 33.3538C134.436 33.3538 132.941 32.7579 131.705 31.5661C130.475 30.3743 129.859 28.904 129.859 27.1554C129.859 25.4068 130.479 23.9365 131.715 22.7545C132.956 21.5725 134.46 20.9766 136.238 20.9766C137.977 20.9766 139.457 21.5773 140.678 22.774C141.894 23.9659 142.51 25.4263 142.51 27.1505ZM132.961 25.9783H139.442C139.296 25.2358 138.934 24.6204 138.363 24.1319C137.791 23.6386 137.083 23.3944 136.233 23.3944C135.383 23.3944 134.665 23.6435 134.074 24.1417C133.478 24.6399 133.107 25.2505 132.961 25.9783Z'
        fill='#512248'
      />
      <path
        d='M151.937 32.0154C153.324 32.0154 154.574 31.61 155.693 30.8041C156.812 29.9981 157.598 28.9529 158.057 27.6731H159.532C159.049 29.329 158.101 30.6868 156.694 31.7516C155.288 32.8165 153.705 33.344 151.942 33.344C149.749 33.344 147.883 32.5722 146.339 31.0239C144.796 29.4755 144.029 27.6096 144.029 25.4311C144.029 23.2527 144.801 21.3868 146.339 19.8384C147.878 18.2901 149.749 17.5183 151.942 17.5183C153.705 17.5183 155.288 18.0507 156.694 19.1106C158.101 20.1755 159.049 21.5333 159.532 23.1892H158.057C157.598 21.9094 156.812 20.8691 155.693 20.0582C154.574 19.2523 153.324 18.8469 151.937 18.8469C150.134 18.8469 148.601 19.4916 147.336 20.7762C146.071 22.0609 145.436 23.6141 145.436 25.4311C145.436 27.2482 146.071 28.8014 147.336 30.086C148.596 31.3707 150.13 32.0154 151.937 32.0154Z'
        fill='#512248'
      />
      <path
        d='M161.442 33.002V17.8601H162.815V23.2916H162.854C163.137 22.7006 163.66 22.1633 164.417 21.6846C165.179 21.206 165.994 20.9666 166.869 20.9666C168.295 20.9666 169.409 21.3916 170.21 22.2464C171.011 23.1011 171.411 24.2295 171.411 25.6264V32.9971H170.039V25.6264C170.039 24.63 169.736 23.8289 169.13 23.2135C168.524 22.6029 167.728 22.2952 166.747 22.2952C165.765 22.2952 164.871 22.642 164.06 23.3356C163.254 24.0292 162.834 24.8449 162.81 25.7876V32.9922H161.442V33.002Z'
        fill='#512248'
      />
      <path
        d='M183.813 24.9476V33.0021H182.44V31.0239H182.401C181.39 32.5723 179.93 33.344 178.02 33.344C176.227 33.344 174.904 32.7579 174.044 31.5856C173.355 30.6429 173.155 29.6221 173.438 28.5182C173.722 27.4143 174.435 26.6328 175.578 26.1785C176.169 25.9343 176.843 25.8171 177.595 25.8171H182.44V24.9476C182.44 24.1271 182.147 23.4823 181.561 23.0085C180.975 22.5396 180.096 22.3003 178.928 22.3003C177.785 22.3003 176.921 22.5201 176.345 22.9548C175.768 23.3944 175.475 23.9659 175.475 24.679H174.044C174.044 23.5898 174.484 22.6959 175.368 22.0023C176.252 21.3087 177.439 20.9619 178.933 20.9619C180.467 20.9619 181.664 21.3282 182.528 22.0609C183.378 22.8034 183.813 23.7656 183.813 24.9476ZM182.44 27.1896H177.595C176.301 27.1896 175.436 27.6048 174.992 28.44C174.615 29.1532 174.65 29.8712 175.094 30.599C175.631 31.4733 176.52 31.9471 177.761 32.0106C177.844 32.0106 177.927 32.0106 178.025 32.0106C179.236 32.0106 180.277 31.6394 181.146 30.892C182.015 30.1447 182.45 29.1776 182.45 27.9955V27.1896H182.44Z'
        fill='#512248'
      />
      <path
        d='M186.284 19.0618C186.104 18.8811 186.011 18.6662 186.011 18.4268C186.011 18.1826 186.104 17.9726 186.284 17.7918C186.465 17.6111 186.68 17.5183 186.919 17.5183C187.164 17.5183 187.374 17.6111 187.554 17.7918C187.735 17.9726 187.828 18.1875 187.828 18.4268C187.828 18.671 187.735 18.8811 187.554 19.0618C187.374 19.2425 187.159 19.3353 186.919 19.3353C186.68 19.3353 186.465 19.2474 186.284 19.0618ZM186.236 21.3135H187.608V33.0021H186.236V21.3135Z'
        fill='#512248'
      />
      <path
        d='M190.031 33.0021V21.3136H191.403V23.2918H191.442C191.726 22.7008 192.248 22.1635 193.005 21.6848C193.767 21.2061 194.583 20.9668 195.457 20.9668C196.884 20.9668 197.997 21.3918 198.798 22.2465C199.599 23.1013 200 24.2296 200 25.6266V32.9972H198.627V25.6266C198.627 24.6302 198.325 23.8291 197.719 23.2137C197.113 22.6031 196.317 22.2954 195.335 22.2954C194.354 22.2954 193.46 22.6422 192.649 23.3358C191.843 24.0294 191.423 24.8451 191.398 25.7878V32.9924H190.031V33.0021Z'
        fill='#512248'
      />
    </svg>
  );
}
