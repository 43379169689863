import { useState } from 'react';
import { Button } from 'ui-kit/Button';
import { scrollTo } from 'utils';
import { Navigation } from './Navigation';
import burgerOpen from '../../../../../assets/burger_open.png';
import burgerClsoe from '../../../../../assets/burger_close.png';

export const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='hidden lg:inline-block'>
      <Button
        className='h-[3.375rem] w-[3.375rem] !bg-transparent !p-0 sm:h-[2.5rem] sm:w-[2.5rem]'
        variant='primary'
        onClick={() => setIsOpen((state) => !state)}
      >
        <img src={burgerOpen} alt='burger menu open' />
      </Button>

      <div
        className='fixed h-full w-[320px] right-0 top-0 transition-transform duration-300 ease-out'
        style={{ transform: isOpen ? 'translateX(0)' : 'translateX(100%)' }}
      >
        <div className='fixed top-0 right-0 bg-[#FBF4F1] w-[320px] h-full -z-10 '></div>

        <div className='flex justify-end pl-[3em] pr-10 md:px-2 pt-5 sm:pt-2 z-10'>
          <Button
            className='h-[3.375rem] w-[3.375rem] !p-0 !bg-transparent sm:h-[2.5rem] sm:w-[2.5rem]'
            variant='primary'
            onClick={() => setIsOpen((state) => !state)}
          >
            <img src={burgerClsoe} alt='burger menu clsoe' />
          </Button>
        </div>

        <div className='pl-[3em] pr-[4.75em] md:px-[2.25em] py-[1.25em] z-10 lg:pr-[3em]'>
          <Navigation place='burger' setIsOpen={setIsOpen} />
        </div>
      </div>
    </div>
  );
};
