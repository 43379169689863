export function Cloud3 () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="356"
      height="103"
      viewBox="0 0 356 103"
      fill="none"
    >
      <path
        d="M356 102.974L251.052 80.6536C251.208 79.9561 251.286 79.2328 251.286 78.4836C251.286 72.7743 246.63 68.15 240.88 68.15C239.007 68.15 237.29 68.6667 235.781 69.5192C234.272 62.2082 227.768 56.7314 219.963 56.7314C216.842 56.7314 213.954 57.6356 211.482 59.1339C209.765 50.2729 201.961 43.5819 192.543 43.5819C186.663 43.5819 181.408 46.1911 177.87 50.2987C174.982 43.1427 168.062 38.0534 159.893 37.7951C160.647 35.1859 161.09 32.4216 161.09 29.5541C161.09 13.227 147.77 0 131.328 0C114.886 0 101.592 13.2012 101.566 29.5024C100.161 29.2441 98.7039 29.0891 97.221 29.0891C84.2131 29.0891 73.6507 39.5518 73.6507 52.4946C73.6507 53.1146 73.7027 53.7346 73.7547 54.3547C73.4686 54.3547 73.1824 54.303 72.8702 54.303C61.2672 54.303 51.8494 63.6549 51.8494 75.1769C51.8494 77.3469 52.1877 79.4394 52.8121 81.4028L0 103H356V102.974Z"
        fill="url(#paint0_linear_1_224)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_224"
          x1="179.379"
          y1="-11.4444"
          x2="177.121"
          y2="177.996"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBC5B7" />
          <stop offset="1" stopColor="#FFF7F8" />
        </linearGradient>
      </defs>
    </svg>
  );
}