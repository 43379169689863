import {
  DialogClose,
  DialogContent,
  DialogContentProps,
  DialogOverlay,
  DialogPortal,
  DialogProps,
  DialogTrigger,
  Root,
} from '@radix-ui/react-dialog';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';
import { Close } from 'ui-kit/icons/Close';
import './styles.css';
import { useLockedScroll } from 'hooks/useLockedScroll';

interface DialogP extends Omit<DialogProps, 'open' | 'onOpenChange'> {
  open: DialogProps['open'];
  onOpenChange: DialogProps['onOpenChange'];
  trigger?: ReactNode;
  contentProps?: DialogContentProps;
  showCloseButton?: boolean;
  disableInteractOutside?: boolean;
}

export function Dialog({
  contentProps,
  disableInteractOutside = false,
  showCloseButton = true,
  ...props
}: DialogP) {
  useLockedScroll(props.open);

  return (
    <Root {...props}>
      {props.trigger && <DialogTrigger asChild>{props.trigger}</DialogTrigger>}
      <AnimatePresence>
        {props.open && (
          <DialogPortal key='dialog' forceMount>
            {props.modal ? (
              <DialogOverlay asChild>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="fixed inset-0 bg-black bg-opacity-70 z-[10]"
                />
              </DialogOverlay>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black bg-opacity-70 pointer-events-auto z-[10]"
                onClick={() => {
                  if (!disableInteractOutside) props.onOpenChange?.(false);
                }}
              />
            )}
            <DialogContent
              onInteractOutside={(e) => {
                if (disableInteractOutside) e.preventDefault();
              }}
              {...contentProps}
              asChild
            >
              <motion.div
                initial={{ opacity: 0, scale: 0.9, y: '-50%', x: '-50%' }}
                animate={{ opacity: 1, scale: 1, y: '-50%', x: '-50%' }}
                exit={{ opacity: 0, scale: 0.9, y: '-50%', x: '-50%' }}
                className="fixed top-1/2 left-1/2 outline-none border-dialog rounded-3xl bg-white text-[#512248] z-[11]"
              >
                {showCloseButton && (
                  <DialogClose className="absolute top-4 right-4 z-10">
                    <Close />
                  </DialogClose>
                )}
                {props.children}
              </motion.div>
            </DialogContent>
          </DialogPortal>
        )}
      </AnimatePresence>
    </Root>
  );
}
