import clsx from 'clsx';
import { FormProvider, useForm } from 'react-hook-form';
import { Dialog } from 'ui-kit/Dialog';
import { EmailStep } from './EmailStep';
import { ConnectEmailForm } from './scheme';
import { authApi } from 'services/auth';
import { AxiosError } from 'axios';
import { errorMessageHandler } from 'utils/errorMessages';

interface ConnectWalletDialogProps {
  isOpen: boolean;
  onOpenChange: (val: boolean) => void;
  onSuccessfulConnect?: (shouldContinue: boolean) => void;
}

export function ConnectEmailDialog({
  isOpen,
  onOpenChange,
  onSuccessfulConnect,
}: ConnectWalletDialogProps) {
  const form = useForm<ConnectEmailForm>({
    values: {
      step: 'email',
      email: '',
      otp: '',
    },
  });

  const step = form.watch('step');

  const handleOnOpenChange = (val: boolean, shouldContinue: boolean = true) => {
    onOpenChange(val);

    if (val) return;

    if (step === 'success') {
      onSuccessfulConnect?.(shouldContinue);
    }

    form.reset();
  };

  const handleEmailSubmit = async (data: ConnectEmailForm) => {
    try {
      const res = await authApi.connectEmail(data.email);

      if (res) {
        handleOnOpenChange(false);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const message = errorMessageHandler(error.response?.data.message);

        form.setError('email', { message });
      }
      console.warn(error);
    }
  };

  const handleOtpSubmit = async (data: ConnectEmailForm) => {
    try {
      await authApi.connectEmail(data.email);

      form.setValue('step', 'success');
    } catch (error) {
      if (error instanceof AxiosError) {
        const message = errorMessageHandler(error.response?.data.message);

        form.setError('otp', { message });
      }
      console.warn(error);
    }
  };

  const handleSubmit = async (data: ConnectEmailForm) => {
    if (data.step === 'email') {
      await handleEmailSubmit(data);
    } else if (data.step === 'otp') {
      await handleOtpSubmit(data);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={handleOnOpenChange}
      trigger={undefined}
      modal={false}
    >
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        className={clsx(
          'flex flex-col gap-4 w-screen relative sm:w-[320px]',
          step === 'email' && 'max-w-[517px] p-10',
          step === 'otp' && 'max-w-[517px] p-8',
          step === 'success' && 'max-w-[517px] p-8',
          'pt-[3.125rem]'
        )}
      >
        <FormProvider {...form}>
          {step === 'email' && <EmailStep />}
        </FormProvider>
      </form>
    </Dialog>
  );
}
