import { InputHTMLAttributes, forwardRef } from "react";

export const FormField = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
  return (
    <input
      type="text"
      className="h-[3.125rem] px-6 py-4 rounded-lg bg-white bg-opacity-5 font-medium outline-none placeholder:text-white"
      ref={ref}
      {...props}
    />
  );
});
