import clsx from "clsx";
import { HTMLAttributes, ReactNode } from "react";

interface FeatureCardProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  description: ReactNode;
  tags: string[];
  classNames?: {
    tag?: string;
  };
}

export function FeatureCard({
  title,
  description,
  tags,
  className,
  classNames,
  ...props
}: FeatureCardProps) {
  return (
    <div className="sticky top-0 h-screen flex justify-center items-center">
      <div
        className={clsx(
          'absolute top-1/2 flex flex-col gap-6 w-[653px] lg:w-[70vw] p-10 lg:p-4 rounded-3xl shadow-sm',
          className
        )}
        {...props}
      >
        <h3 className="max-w-[540px] font-medium text-[2.625rem] lg:text-[1.8rem] leading-snug tracking-[0.84px]">
          {title}
        </h3>
        <div className="font-normal text-base leading-6 opacity-70 lg:text-[0.8rem]">
          {description}
        </div>
        <div className="flex flex-wrap gap-2">
          {tags.map((tag, i) => (
            <div
              key={i}
              className={clsx(
                'flex justify-center items-center px-4 py-2 rounded-[0.625rem]',
                classNames?.tag ? classNames.tag : ' bg-white bg-opacity-15'
              )}
            >
              {tag}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
