import { DialogClose } from '@radix-ui/react-dialog'
import { AxiosError } from 'axios'
import { TARGET_NETWORKS } from 'configurations/wagmi'
import { TOKEN_ADDRESS } from 'constants/vestingContract'
import { formatEther } from 'ethers/lib/utils'
import { useConnectedWalletIcon } from 'hooks/useConnectedWalletIcon'
import { useCopyToClipboard } from 'hooks/useCopyToClipboard'
import { useSigner } from 'hooks/useSigner'
import { ReactNode, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { authApi } from 'services/auth'
import { tokensApi } from 'services/tokens'
import { tokensContractService } from 'services/tokensContract'
import { useMainStore } from 'store'
import { useUserStore } from 'store/user'
import { Button } from 'ui-kit/Button'
import { Dialog } from 'ui-kit/Dialog'
import { Copy } from 'ui-kit/icons/Copy'
import { Info } from 'ui-kit/icons/Info'
import { InfoWithGradient } from 'ui-kit/icons/InfoWithGradient'
import { Logo } from 'ui-kit/icons/Logo'
import { Tick } from 'ui-kit/icons/Tick'
import { formatAddress } from 'utils'
import { useAccount, useBalance, useSwitchChain } from 'wagmi'

interface BalanceDialogProps {
  children: ReactNode
}

export function BalanceDialog({ children }: BalanceDialogProps) {
  const [isOpen, setOpen] = useState(false)
  const [copiedText, copyToClipboard] = useCopyToClipboard(2000)
  const [copiedReferralCode, copyReferralCode] = useCopyToClipboard(2000)
  const { address, chain } = useAccount()
  const walletIcon = useConnectedWalletIcon()
  const [isClaimAvailable] = useMainStore((state) => [state.isClaimAvailable])
  const [user] = useUserStore((s) => [s.user])
  const [balance, setBalance] = useState(0)
  const [availableBalance, setAvailableBalance] = useState(0)
  const { switchChainAsync } = useSwitchChain()
  const signer = useSigner()
  const [isClaiming, setIsClaiming] = useState(false)
  const { t } = useTranslation()

  const { data: tokenBalance, refetch } = useBalance({
    address: address,
    chainId: TARGET_NETWORKS.id,
    token: TOKEN_ADDRESS as `0x${string}`
  })

  const isFirstRender = useRef(true)
  useLayoutEffect(() => {
    if ((!isOpen && !isFirstRender.current) || !address || !signer) return

    tokensApi.getTokensBalance().then(async (res) => {
      await authApi.getUser()
      await refetch()
      if (isClaimAvailable) {
        const availableBalance = await tokensContractService.calculateClaimable(
          address,
          res,
          signer
        )
        const availableBalanceFormatted =
          Math.round(+formatEther(availableBalance) * 100) / 100
        setAvailableBalance(availableBalanceFormatted)
        claimForm.setValue('value', availableBalanceFormatted)
      }
      setBalance(res)
    })

    return () => {
      isFirstRender.current = false
    }
  }, [isOpen, tokenBalance?.formatted, address, signer, isClaimAvailable])

  const formattedTokenBalance = useMemo(
    () =>
      Math.ceil((balance - Number(tokenBalance?.formatted ?? 0)) * 100) / 100 ??
      0,
    [balance, tokenBalance?.formatted]
  )

  const claimForm = useForm<{ value: number }>()

  const handleWalletCopy = () => {
    if (address) copyToClipboard(address)
  }

  const handleBuyMore = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleClaimSubmit = async () => {
    if (!signer || !chain || !switchChainAsync) return

    try {
      setIsClaiming(true)
      if (chain.id !== TARGET_NETWORKS.id)
        await switchChainAsync({ chainId: TARGET_NETWORKS.id })

      await tokensContractService.claim(balance, signer)

      handleOpenChange(false)
    } catch (error) {
      if (error instanceof AxiosError) {
        claimForm.setError('root', { message: error.response?.data?.message })
      } else if (error instanceof Error) {
        claimForm.setError('root', {
          message:
            error.message.length > 100
              ? t('errorMessage.somethingWrong')
              : error.message
        })
      }
    } finally {
      setIsClaiming(false)
    }
  }

  const handleOpenChange = (val: boolean) => {
    setOpen(val)
    if (!val) {
      claimForm.reset()
    }
  }

  return (
    <Dialog trigger={children} open={isOpen} onOpenChange={handleOpenChange}>
      <div className='flex flex-col gap-6 w-[517px] sm:w-[300px] p-8 pt-[3.125rem]'>
        <div className='flex justify-between sm:flex-col sm:justify-start sm:items-start sm:gap-4 items-center'>
          <div className='flex items-center gap-4 p-4 rounded-xl bg-[#F2F2F2] font-bold text-sm leading-5'>
            <img src={walletIcon} className='w-5 h-5' alt='' />
            {address && formatAddress(address, 4, 3)}
            <button disabled={!!copiedText} onClick={handleWalletCopy}>
              {!!copiedText ? <Tick color='#512248' /> : <Copy />}
            </button>
          </div>

          {!!user.referralCode && (
            <div className='flex items-center p-4 rounded-xl bg-[#F2F2F2] font-bold text-sm leading-5'>
              <span className='mr-1'>{t('balanceDialog.refCode')}</span>
              {address && user.referralCode.code}
              <button
                className='ml-2'
                disabled={!!copiedReferralCode}
                onClick={() => {
                  if (user.referralCode?.code)
                    copyReferralCode(user.referralCode.code)
                }}
              >
                {!!copiedReferralCode ? <Tick color='#512248' /> : <Copy />}
              </button>
            </div>
          )}
        </div>
        <div className='flex justify-between sm:flex-col sm:gap-4'>
          <div className='flex flex-col gap-1'>
            <div className='font-medium text-xs leading-5 text-[#978D95]'>
              {t('balanceDialog.currentBalance')}
            </div>
            <div className='flex items-center gap-2 font-medium text-2xl leading-snug text-[#512248]'>
              <Logo width={32} height={32} />
              {formattedTokenBalance} $PARCHA
            </div>
          </div>
          {isClaimAvailable && (
            <div className='flex flex-col  gap-1'>
              <div className='flex items-center gap-1 font-medium text-xs leading-5 text-[#978D95]'>
                {t('balanceDialog.availableClaim')}
                <InfoWithGradient />
              </div>
              <div className='flex items-center gap-2 font-medium text-2xl leading-snug text-[#512248]'>
                <Logo width={32} height={32} />
                {availableBalance} $PARCHA
              </div>
            </div>
          )}
        </div>
        {!isClaimAvailable && (
          <div className='flex flex-col gap-4'>
            {user.kicked && (
              <div className='flex items-center gap-2.5 p-3 rounded-lg bg-[#F9F1F5] font-medium text-xs leading-[0.875rem] text-[#856E80]'>
                <Info className='min-w-4' />
                <span>{t('balanceDialog.blocked')}</span>
              </div>
            )}
            <div className='flex items-center gap-2.5 p-3 rounded-lg bg-[#F9F1F5] font-medium text-xs leading-[0.875rem] text-[#856E80]'>
              <Info className='min-w-4' />
              <span>{t('balanceDialog.lunched')}</span>
            </div>
            {!!user.bonusForInvitedPercent && (
              <div className='flex items-center gap-2.5 p-3 rounded-lg bg-[#F9F1F5] font-medium text-xs leading-[0.875rem] text-[#856E80]'>
                <Info className='min-w-4' />
                <span>
                  {t('balanceDialog.bonus')} {user.bonusForInvitedPercent}%
                </span>
              </div>
            )}
            <DialogClose asChild>
              <Button
                onClick={handleBuyMore}
                disabled={user.kicked}
                variant='primary'
              >
                <Logo />
                {t('balanceDialog.buyMore')} $PARCHA
              </Button>
            </DialogClose>
          </div>
        )}
        {isClaimAvailable && (
          <form
            onSubmit={claimForm.handleSubmit(handleClaimSubmit)}
            className='flex flex-col gap-4 pt-4 border-t border-[#C1C1C1] border-opacity-50'
          >
            <div>
              <div className='flex flex-col gap-1'>
                <label
                  htmlFor='amountToClaim'
                  className='font-medium text-xs leading-5 text-[#978D95]'
                >
                  {t('balanceDialog.amountClaim')}
                </label>
                <label className='flex items-center gap-4 p-3.5 rounded-lg bg-[#F2F2F2] text-[#512248] cursor-text'>
                  <input
                    type='number'
                    className='grow bg-transparent font-medium text-base leading-snug outline-none hero-form-field placeholder:text-[#512248]'
                    placeholder='0'
                    id='amountToClaim'
                    min={0.01}
                    step={0.01}
                    max={availableBalance}
                    readOnly
                    required
                    {...claimForm.register('value', {
                      valueAsNumber: true
                    })}
                  />
                  <button
                    type='button'
                    className='font-medium text-xs leading-5 text-gradient-darker'
                    onClick={() =>
                      claimForm.setValue('value', availableBalance)
                    }
                  >
                    {t('balanceDialog.max')} {availableBalance}
                  </button>
                </label>
              </div>
              {claimForm.formState.errors.root && (
                <p className='mt-1 text-red-500'>
                  {claimForm.formState.errors.root?.message}
                </p>
              )}
              {claimForm.formState.errors.value && (
                <p className='mt-1 text-red-500'>
                  {claimForm.formState.errors.value?.message}
                </p>
              )}
            </div>
            <Button
              type='submit'
              variant='primary'
              disabled={isClaiming || availableBalance <= 0}
              onClick={handleBuyMore}
            >
              <Logo />
              {isClaiming
                ? t('balanceDialog.claiming')
                : `${t('balanceDialog.claim')} $PARCHA`}
            </Button>
          </form>
        )}
      </div>
    </Dialog>
  )
}
