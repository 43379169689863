import { MAX_AMOUNT_IN_USD_PER_WALLET, SEVEN_DAYS_IN_MS } from 'constants/sale';
import { mainStore } from 'store';
import { BonusProgram, Round, Status } from 'types';
import { minmax, toCeilFixed } from './number';
import { userStore } from 'store/user';

export const scrollTo = (el: HTMLElement | null) => {
  if (el) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
};

export const formatAddress = (
  address: string,
  start: number = 6,
  end: number = 4
) => {
  return `${address.slice(0, start)}...${address.slice(-end)}`;
};

export const calcStatus = (): Status => {
  const now = Date.now();
  const { currentRound, isClaimAvailable } = mainStore.getState();
  if (!currentRound || isClaimAvailable) return 'claim';

  const endOfSalesTimestamp = new Date(currentRound.finishAt).getTime();
  if (endOfSalesTimestamp > now) return 'ongoingSale';
  if (endOfSalesTimestamp + SEVEN_DAYS_IN_MS > now) return 'noSale';

  return 'claim';
};

export const calcAmountOfTokens = (
  valueInUSD: number,
  price = 1,
  maxAmountInUSD = MAX_AMOUNT_IN_USD_PER_WALLET
) => {
  const valueWithMax = Math.min(valueInUSD, maxAmountInUSD); // value may not exceed max amount

  const amountOfTokens = valueWithMax / price; // amount of tokens

  return Math.ceil(amountOfTokens * 100) / 100; // round to 2 decimal places if needed
};

export const calcAmountOfTokensToShow = (
  valueInUSD: number,
  round: Round | null,
  bonusProgram: BonusProgram | null,
  includeReferrerBonus = false
) => {
  if (!round) return 0;

  const { user } = userStore.getState();
  const { currentReferralProgram } = mainStore.getState();

  const valueInUSDWithMax = minmax(valueInUSD, 0, round.maxPurchasePerWallet); // value may not exceed max amount
  const amountOfTokens = valueInUSDWithMax / round.tokenPrice; // amount of tokens

  const bonusProgramPercent = (bonusProgram?.percentAmount || 0) / 100;
  const bonusForInvitedPercent = (user?.bonusForInvitedPercent || 0) / 100;

  // Calculate amount of tokens with bonus if it's enabled
  let bonusAmount = bonusProgramPercent * amountOfTokens;

  if (
    !bonusProgram ||
    (bonusProgram?.minPurchaseAmountEnabled &&
      valueInUSDWithMax < bonusProgram.minPurchaseAmount)
  ) {
    bonusAmount = 0;
  }

  // Calculate amount of tokens with referrer bonus if it's enabled
  const referrerBonusPercentAmount =
    (currentReferralProgram?.refereeBonusPercentAmount || 0) / 100;

  let referrerBonusAmount = referrerBonusPercentAmount * amountOfTokens;

  if (
    !currentReferralProgram ||
    !includeReferrerBonus ||
    (currentReferralProgram?.minAmountToGetBonusEnabled &&
      valueInUSDWithMax < currentReferralProgram.minAmountToGetBonus)
  ) {
    referrerBonusAmount = 0;
  }

  // Calculate amount of tokens with referee bonus if it's enabled
  let refereeBonusAmount = bonusForInvitedPercent * amountOfTokens;

  return toCeilFixed(
    amountOfTokens + bonusAmount + refereeBonusAmount + referrerBonusAmount
  );
};
