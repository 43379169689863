import { useForm } from 'react-hook-form'
import { Button } from 'ui-kit/Button'
import { FormField } from './FormField'
import { FormTextArea } from './FormTextArea'
import { FeedbackFormType } from './scheme'
import './styles.css'
import { api } from 'services/api'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export function FeedbackForm() {
  const form = useForm<FeedbackFormType>()
  const {
    formState: { isSubmitSuccessful },
    reset
  } = form
  const handleSubmit = async (data: FeedbackFormType) => {
    await api.sendMessage(data.name, data.email, data.message)
    form.reset()
  }
  const { t } = useTranslation()

  useEffect(() => {
    if (isSubmitSuccessful) {
      setTimeout(() => {
        reset()
      }, 5000)
    }
  }, [isSubmitSuccessful])

  return (
    <div className='bg-feedback-form text-white  rounded-[2.5rem] mb-6 pl-[4.875rem] pr-[4.75rem] lg:px-[2rem] lg:py-[2rem] py-[4.75rem]'>
      <div className='flex justify-between lg:flex-col lg:items-center lg:gap-6'>
        <div className='flex flex-col gap-4'>
          <h2 className='max-w-[508px] font-bold text-[3.375rem] leading-none sm:text-[3rem]'>
            {t('feedbackForm.title.firstPart')}
            <br />
            {t('feedbackForm.title.secondPart')}
          </h2>
        </div>

        <form
          action=''
          method='POST'
          className='grow flex flex-col gap-2 max-w-[558px] md:w-full ml-4'
          onSubmit={form.handleSubmit(handleSubmit)}
        >
          <div className='grid grid-cols-2 gap-2 md:grid-cols-1'>
            <FormField
              placeholder={t('feedbackForm.name')}
              required
              {...form.register('name')}
            />
            <FormField
              type='email'
              placeholder={t('feedbackForm.email')}
              required
              {...form.register('email')}
            />
          </div>
          <FormTextArea
            placeholder={t('feedbackForm.message')}
            required
            {...form.register('message')}
          />
          <Button
            disabled={form.formState.isSubmitting}
            type='submit'
            className='h-[3.125rem] text-[#272D29]'
          >
            {form.formState.isSubmitting
              ? t('feedbackForm.sending')
              : t('feedbackForm.submit')}
          </Button>
        </form>
      </div>

      <AnimatePresence>
        {form.formState.isSubmitSuccessful && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='pt-10 text-center md:text-xl text-3xl'
          >
            {t('feedbackForm.successfullySubmit')}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
