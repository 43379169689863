export const formatNumber = (number: number): string => {
  if (number >= 1000000) {
    return Math.round(number / 100000) / 10 + " million";
  }
  if (number >= 1000) {
    return Math.round(number / 100) / 10 + " thousand";
  }
  if (number >= 100) {
    return Math.round(number / 100) + " hundred";
  }
  return number.toString();
};

export const formatUSD = (value: number) => {
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
};

export const toCeilFixed = (value: number, precision = 2) => {
  const multiplier = Math.pow(10, precision);
  return Math.ceil(value * multiplier) / multiplier;
};

export const minmax = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value, min), max);
}
