import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'ui-kit/Button';
import { ArrowLeft } from 'ui-kit/icons/ArrowLeft';
import { RightArrowWithTail } from 'ui-kit/icons/RightArrowWithTail';
import { PaymentFormType } from './scheme';
import { api } from 'services/api';
import { useTranslation } from 'react-i18next';

export function RefCode() {
  const form = useFormContext<PaymentFormType>();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation()

  if (!form) throw new Error('SelectAsset must be used within a FormProvider');

  const refCode = form.watch('refCode');
  const errorMessage = form.formState.errors.refCode?.message;

  const handleSelectAsset = async () => {
    setIsLoading(true);
    try {
      const isValid = await api.isRefCodeValid(refCode);

      if (!isValid) {
        throw new Error('Invalid referral code');
      }

      form.setValue('step', 'selectAsset');
    } catch (error) {
      if (error instanceof AxiosError) {
        form.setError('refCode', {
          message: error.response?.data.message || error.message,
        });
      } else if (error instanceof Error) {
        form.setError('refCode', {
          message: error.message,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

	useEffect(() => {
		if (errorMessage) {
			setTimeout(() => {
				form.setError('refCode', { message: '' });
			}, 3000)
		}
	}, [errorMessage]);

  return (
    <>
      <div className='flex flex-col items-center gap-2'>
        <div className='my-auto px-3 py-1.5 rounded-lg bg-[#F9F1F5] font-medium text-xs leading-5 text-[#7F6C7B]'>
          {t('refCode.steps')}
        </div>
        <h2 className='font-medium text-2xl leading-snug text-[#512248]'>
          {t('refCode.title')}
        </h2>
      </div>
      <div className='flex flex-col gap-2'>
        <label className='font-medium text-xs leading-5 text-[#978D95]'>
          {t('referral')}
        </label>
        <label className='flex justify-between w-full p-4 rounded-xl bg-[#F2F2F2]'>
          <input
            type='text'
            className='grow bg-transparent font-medium text-base leading-snug outline-none hero-form-field placeholder:text-[#512248]'
            placeholder=''
            id='refCode'
            {...form.register('refCode', {
              onChange: () => form.clearErrors('root'),
            })}
          />
        </label>
        {errorMessage && (
          <div className='text-red-500'>
            {errorMessage}
          </div>
        )}
      </div>
      <div className='flex flex-col gap-4'>
        <Button
          className='h-[3.375rem] transition-opacity duration-300'
          variant='primary'
          onClick={handleSelectAsset}
          disabled={isLoading || !refCode}
        >
          {isLoading ? (
            'Loading...'
          ) : (
            <>
              {t('nextStep')}
              <RightArrowWithTail />
            </>
          )}
        </Button>
        <button
          disabled={isLoading}
          onClick={() => {
            form.setValue('refCode', '');
            form.setValue('step', 'selectAsset');
          }}
          className='flex items-center mx-auto font-bold text-sm leading-4 text-gradient-darker transition-opacity duration-300 disabled:opacity-50 disabled:pointer-events-none'
        >
          {t('skipStep')}
          <ArrowLeft
            width={26}
            height={26}
            className='rotate-180'
            color='#53244A'
          />
        </button>
      </div>
    </>
  );
}
