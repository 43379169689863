import Telegram from 'assets/Telegram.png';
import GitBook from 'assets/GitBook.png';
import X from 'assets/X.png';
import Mail from 'assets/mail.png';
import { MouseEvent } from 'react';
import { scrollTo } from 'utils';
import { useTranslation } from 'react-i18next';

export function Footer() {
  const { t } = useTranslation()
 
  const handleClick = (e: MouseEvent, id: string) => {
    e.preventDefault();
    scrollTo(document.getElementById(id));
  };

  return (
    <footer className='mb-5 py-6 px-8 sm:px-2 rounded-[2rem] bg-white text-black'>
      <div className='flex lg:flex-col lg:gap-6 justify-between items-center'>
        <nav className='flex md:grid md:grid-cols-3 sm:!grid-cols-2 sm:[&>a]:text-center gap-10 xl-1440:gap-5 font-medium text-sm sm:text-[.7rem] leading-snug [&>a]:whitespace-nowrap'>
          <a href='#hero' onClick={(e) => handleClick(e, 'hero')}>
            {t('footer.buyToken')}
          </a>
          <a href='#features' onClick={(e) => handleClick(e, 'features')}>
            {t('footer.platformFeatures')}
          </a>
          <a href='#tokenomics' onClick={(e) => handleClick(e, 'tokenomics')}>
            {t('footer.tokenomics')}
          </a>
          <a href='#roadmap' onClick={(e) => handleClick(e, 'roadmap')}>
            {t('footer.roadmap')}
          </a>
          <a
            href='https://paradisechain.gitbook.io/privacy-policy/'
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('footer.privacyPolicy')}
          </a>
          <a
            href='https://paradisechain.gitbook.io/paradisechain-t-and-c/conditions-and-guidelines-for-service/digital-service-and-service-use-agreement'
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('footer.termsConditions')}
          </a>
          <a
            href='https://paradisechain.gitbook.io/paradisechain-disclaimer'
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('footer.disclaimer')}
          </a>
        </nav>

        <nav className='flex items-center gap-2'>
          <a
            href='https://t.me/paradisechain'
            target='_blank'
            rel='noopener noreferrer'
            aria-label='Telegram'
            className='rounded-full border border-[#D1D3D4]'
          >
            <img src={Telegram} className='w-12 h-12' alt='' />
          </a>
          <a
            href='https://twitter.com/ParadiseChain_'
            target='_blank'
            rel='noopener noreferrer'
            aria-label='X (Twitter)'
            className='rounded-full border border-[#D1D3D4]'
          >
            <img src={X} className='w-12 h-12' alt='' />
          </a>
          <a
            href='https://paradisechain.gitbook.io/paradisechain/'
            target='_blank'
            rel='noopener noreferrer'
            aria-label='GitBook'
            className='rounded-full border border-[#D1D3D4]'
          >
            <img src={GitBook} className='w-12 h-12' alt='' />
          </a>
        </nav>
      </div>
    </footer>
  );
}
