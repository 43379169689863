import axios, { AxiosInstance } from 'axios';
import { API_URL } from 'constants/api';
import { mainStore } from 'store';
import { userStore } from 'store/user';
import { BonusProgram, ReferralProgram, Round, Settings } from 'types';

export class GeneralService {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: API_URL,
      withCredentials: true,
    });
  }

  async getCurrentRound() {
    try {
      mainStore.setState({ isLoading: true });
      const url = '/api/rounds/current';
      const { data } = await this._api.get<Round>(url);

      mainStore.setState({ currentRound: data, isLoading: false });

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async sendMessage(
    name: string,
    email: string,
    message: string
  ): Promise<void> {
    try {
      const url = '/api/mail/send-message';
      await this._api.post(url, { name, email, message });
    } catch (error) {
      console.warn(error);
    }
  }

  async getCurrentBonus() {
    try {
      const url = '/api/bonuses/current';
      const { data } = await this._api.get<BonusProgram>(url);

      mainStore.setState({ currentBonus: data });

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async getCurrentReferralProgram() {
    try {
      const url = '/api/referral-bonuses/current';
      const { data } = await this._api.get<ReferralProgram>(url);

      mainStore.setState({ currentReferralProgram: data });

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async isRefAvailable() {
    try {
      const token = localStorage.getItem('accessToken');
      const { data } = await this._api.get<{ available: boolean }>(
        '/api/referral-bonuses/check-available',
        {
          headers: {
            authorization: token,
          },
        }
      );

      const currentReferralProgram = await this.getCurrentReferralProgram();

      userStore.setState({
        canEnterRefCode: currentReferralProgram?.enabled && data.available,
      });

      return data.available;
    } catch (error) {
      console.warn(error);
    }
    return false;
  }

  async isRefCodeValid(refCode: string) {
    const token = localStorage.getItem('accessToken');
    const { data } = await this._api.get<{ available: boolean }>(
      `/api/referral-bonuses/check-code`,
      {
        params: {
          code: refCode,
        },
        headers: {
          authorization: token,
        },
      }
    );

    return data.available;
  }

  async getProgress() {
    try {
      const {setProgress} = mainStore.getState();

      const url = '/api/sale/progress';
      const { data } = await this._api.get<{
        total: number;
        sold: number;
        claimAvailable: boolean;
      }>(url);

      setProgress(data.total, data.sold);

      return data;
    } catch (error) {
      console.warn(error);
    }
  }

  async getIsClaimAvailable() {
    try {
      const { setIsClaimAvailable } = mainStore.getState();

      const url = '/api/sale/progress';
      const { data } = await this._api.get<{
        claimAvailable: boolean;
      }>(url);

      setIsClaimAvailable(data.claimAvailable);

      return data;
    } catch (error) {
      console.warn(error);
    }
  }

  async getSettings() {
    try {
      const url = '/api/settings';
      const { data } = await this._api.get<Settings>(url);

      mainStore.setState({
        settings: data
      });

      return data;
    } catch (error) {
      console.warn(error);
    }
  }

  async getBanner() {
    try {
      const url = '/api/banners/current'
      const { data } = await this._api.get(url)
      mainStore.setState({ banner: data });
    } catch (error) {
      console.warn(error)
      throw error
    }
  }
}

export const api = new GeneralService();
