import { BonusProgram, IBanner, ReferralProgram, Round, Settings } from 'types';
import { create } from 'zustand';

export interface MainStore {
  isLoading: boolean;
  currentRound: Round | null;
  currentBonus: BonusProgram | null;
  currentReferralProgram: ReferralProgram | null;
  totalPool: number;
  sold: number;
  banner: IBanner | null;
  settings: Settings | null;
  isClaimAvailable: boolean;
  setIsClaimAvailable: (isClaimAvailable: boolean) => void;
  setCurrentRound: (round: Round) => void;
  setIsLoading: (isLoading: boolean) => void;
  setProgress: (totalPool: number, sold: number) => void;
}

export const mainStore = create<MainStore>((set) => ({
  isLoading: false,
  currentRound: null,
  currentBonus: null,
  currentReferralProgram: null,
  totalPool: 0,
  sold: 0,
  banner: null,
  settings: null,
  isClaimAvailable: false,
  setIsClaimAvailable: (isClaimAvailable) => set({ isClaimAvailable }),
  setCurrentRound: (round) => set({ currentRound: round }),
  setIsLoading: (isLoading) => set({ isLoading }),
  setProgress: (totalPool, sold) => set({ totalPool, sold }),
}));

export const useMainStore = mainStore;
