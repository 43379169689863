import birds from 'assets/birds.gif';
import sky1 from 'assets/sky1.svg';
import sky2 from 'assets/sky2.svg';
import sky3 from 'assets/sky3.svg';
import sky4 from 'assets/sky4.svg';
import { MotionConfig, motion, useWillChange } from 'framer-motion';
import { Cloud1 } from 'ui-kit/icons/AnimationFrame/Cloud1';
import { Cloud2 } from 'ui-kit/icons/AnimationFrame/Cloud2';
import { Cloud3 } from 'ui-kit/icons/AnimationFrame/Cloud3';
import { PalmTree1 } from 'ui-kit/icons/AnimationFrame/PalmTree1';
import { PalmTree2 } from 'ui-kit/icons/AnimationFrame/PalmTree2';
import { Waves } from 'ui-kit/icons/AnimationFrame/Waves';
import './styles.css';

export function AnimationFrame() {
  const willChange = useWillChange();

  return (
    <MotionConfig transition={{ duration: 2.5, delay: 2 }}>
      <div className="absolute top-[-10.125rem] left-0 right-0 bottom-0 overflow-hidden">
        <motion.div
          initial={{ opacity: 1, x: '-50%', bottom: '-50%' }}
          animate={{
            opacity: [1, 1, 1, 0],
            x: '-50%',
            bottom: ['0%', '33%', '33%', '80%'],
            scale: [1, 1, 1, 0.4],
          }}
          style={{ willChange }}
          className="absolute left-1/2 w-[21.125rem] h-[21.125rem] rounded-full bg-sun"
        />
        <div className="absolute top-0 left-0 right-0 flex flex-col pointer-events-none">
          <motion.img
            initial={{ x: '0%' }}
            animate={{ x: ['0%', '0%', '0%', '62%'], opacity: [1, 1, 1, 0] }}
            src={sky1}
            alt=""
          />
          <motion.img
            src={sky2}
            initial={{ x: '0%' }}
            animate={{ x: ['0%', '0%', '0%', '-62%'], opacity: [1, 1, 1, 0] }}
            alt=""
          />
          <motion.img
            src={sky3}
            initial={{ x: '0%' }}
            animate={{ x: ['0%', '0%', '0%', '62%'], opacity: [1, 1, 1, 0] }}
            alt=""
          />
          <motion.img
            src={sky4}
            initial={{ x: '0%' }}
            animate={{ x: ['0%', '0%', '0%', '-62%'], opacity: [1, 1, 1, 0] }}
            alt=""
          />
        </div>
        <motion.img
          initial={{
            top: '58.46%',
            left: '21.875%',
          }}
          animate={{
            top: ['58.46%', '30.769%', '30.769%', '9.45%'],
            left: ['21.875%', '26.38%', '26.38%', '91%'],
            transitionEnd: {
              left: '100vw',
              display: 'none',
            },
          }}
          src={birds}
          alt="birds"
          className="birds"
          style={{ willChange }}
        />
        <motion.div
          initial={{ top: '39.45%', left: '22.92vw', opacity: 1 }}
          animate={{
            left: ['22.92vw', '22.92vw', '22.92vw', '7.29vw'],
            opacity: [1, 1, 1, 0],
            transitionEnd: {
              left: '-100vw',
              display: 'none',
            },
          }}
          className="absolute pointer-events-none"
          style={{ willChange }}
        >
          <Cloud3 />
        </motion.div>
        <motion.div
          initial={{ top: '44.17%', right: '26.46vw', opacity: 1 }}
          animate={{
            right: ['26.46vw', '26.46vw', '26.46vw', '7.29vw'],
            opacity: [1, 1, 1, 0],
            transitionEnd: {
              left: '-100vw',
              display: 'none',
            },
          }}
          className="absolute pointer-events-none"
          style={{ willChange }}
        >
          <div className="scale-x-[-1]">
            <Cloud3 />
          </div>
        </motion.div>
        <motion.div
          initial={{ top: '66%', left: '0%', opacity: 1 }}
          animate={{
            left: ['0%', '0%', '0%', '-14.03%'],
            opacity: [1, 1, 1, 0],
            transitionEnd: {
              left: '-100vw',
              display: 'none',
            },
          }}
          className="absolute pointer-events-none -translate-y-full"
          style={{ willChange }}
        >
          <Cloud1 />
        </motion.div>
        <motion.div
          initial={{ top: '66%', right: '0%', opacity: 1 }}
          animate={{
            right: ['0%', '0%', '0%', '-22.5%'],
            opacity: [1, 1, 1, 0],
            transitionEnd: {
              right: '-100vw',
              display: 'none',
            },
          }}
          className="absolute pointer-events-none -translate-y-full"
          style={{ willChange }}
        >
          <Cloud2 />
        </motion.div>
        <motion.div
          initial={{
            top: '66%',
            opacity: 1,
          }}
          animate={{
            top: ['66%', '66%', '66%', '84%'],
            opacity: [1, 1, 1, 0],
          }}
          className="water"
          style={{ willChange }}
        >
          <Waves />
        </motion.div>
        <motion.div
          className="absolute inset-0 bg-black"
          initial={{ opacity: 1 }}
          animate={{ opacity: 0 }}
          style={{ willChange }}
        />
        <motion.div
          animate={{
            x: ['-218px', '-484px', '-620px'],
            y: ['-118px', '-118px', '-423px'],
            rotateZ: ['17.7deg', '0deg', '0deg', '0deg'],
            color: ['#000000', '#000000', '#000000', '#F7D5D4'],
          }}
          className="absolute top-0 left-0"
          style={{ willChange }}
        >
          <PalmTree1 />
        </motion.div>
        <motion.div
          animate={{
            x: ['181px', '503px', '563px'],
            y: ['-223px', '-223px', '-534px'],
            rotateZ: ['-13.16deg', '0deg', '0deg', '0deg'],
            color: ['#000000', '#000000', '#000000', '#F7D5D4'],
          }}
          className="absolute top-0 right-0"
          style={{ willChange }}
        >
          <PalmTree2 />
        </motion.div>
      </div>
    </MotionConfig>
  );
}
