import axios, { AxiosInstance } from 'axios';
import { Buffer } from 'buffer';
import { API_URL } from 'constants/api';
import { userStore } from 'store/user';
import { AuthRefreshResponse, AuthResponse } from 'types/Auth';
import { User } from 'types/User';
import { api } from './api';

export class AuthService {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: API_URL,
      withCredentials: true,
    });
  }

  async auth(wallet: string, signature: string): Promise<AuthResponse | null> {
    try {
      const url = '/api/auth';
      const { data } = await this._api.post(url, { wallet, signature });

      localStorage.setItem('accessToken', data.tokens.access);

      await authApi.getUser();

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async refresh(wallet: string): Promise<AuthRefreshResponse | null> {
    try {
      const url = '/api/auth/refresh';
      const { data } = await this._api.post(url);

      const parsedData = JSON.parse(
        Buffer.from(data.access.split('.')[1], 'base64').toString('utf-8')
      );

      if (parsedData.wallet !== wallet) {
        throw new Error('Wallets do not match');
      }

      if (parsedData.exp * 1000 < Date.now()) {
        throw new Error('Refresh token expired');
      }

      localStorage.setItem('accessToken', data.access);

      this.getUser();

      return data;
    } catch (error) {
      localStorage.removeItem('accessToken');
      console.warn(error);
      throw error;
    }
  }

  async getUser() {
    try {
      userStore.setState({ isLoading: true });
      const token = localStorage.getItem('accessToken');
      const { data } = await this._api.get<User>('/api/users/@me', {
        headers: {
          authorization: token,
        },
      });

      const { data: refData } = await this._api.get<{ available: boolean }>(
        '/api/referral-bonuses/check-available',
        {
          headers: {
            authorization: token,
          },
        }
      );

      const currentReferralProgram = await api.getCurrentReferralProgram();

      await api.getIsClaimAvailable();

      userStore.setState({
        user: data,
        canEnterRefCode: currentReferralProgram?.enabled && refData.available,
      });

      return data.email && data.email.length > 0;
    } catch (error) {
      console.warn(error);
    } finally {
      userStore.setState({ isLoading: false });
    }
    return false;
  }

  async requestOTP(email: string) {
    const url = '/api/otp/request';
    const { data } = await this._api.post(url, { email: email.toLowerCase() });

    return data;
  }

  async connectEmail(email: string) {
    const url = '/api/users/email/connect';
    const token = localStorage.getItem('accessToken');

    await this._api.post(
      url,
      { email: email.toLowerCase(), },
      {
        headers: {
          authorization: token,
        },
      }
    );

		const res = this.getUser()

    return res;
  }
}

export const authApi = new AuthService();
