import { AnimatePresence, motion, useWillChange } from 'framer-motion'
import { useMemo } from 'react'
import { useMainStore } from 'store'
import { ProgressBar } from 'ui-kit/ProgressBar'
import { minmax } from 'utils/number'
import { AnimationFrame } from './AnimationFrame'
import { HeroForm } from './HeroForm'
import { Timer } from './Timer'
import './styles.css'
import { Header } from '../Header'
import { useTranslation } from 'react-i18next'
import { Banner } from '../Banner'

export function Hero() {
  const willChange = useWillChange()
  const { t } = useTranslation()
  const [currentRound, total, sold] = useMainStore((state) => [
    state.currentRound,
    state.totalPool,
    state.sold
  ])

  const formattedSold = useMemo(() => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(sold)
  }, [sold])

  return (
    <div className='min-h-[100vh] sm:!min-h-[100svh] flex flex-col justify-between'>
      <div>
        <Banner />

        <Header />
      </div>

      <AnimationFrame />

      <div className='absolute top-[-10.125rem] left-0 right-0 bottom-0 bg-hero z-[-1]' />

      <motion.div
        initial={{ opacity: 0, pointerEvents: 'none' }}
        animate={{ opacity: 1, pointerEvents: 'auto' }}
        transition={{ delay: 5 }}
        className='relative h-full flex flex-col gap-5 sm:gap-2 justify-around z-1 pb-[6.125rem] sm:pb-[3rem] text-[#49122D]'
        style={{ willChange }}
        id='hero'
      >
        <div className='relative flex flex-col justify-between items-center mx-auto gap-2 '>
          <h1 className='font-medium text-[2.5rem] lg:text-[2rem] sm:!text-[1.2rem] tracking-[0.96px] text-center'>
            {currentRound?.name}
          </h1>
        </div>

        <Timer />

        <div className='flex flex-col justify-center items-center mx-auto gap-2'>
          <h1 className='font-medium text-[3.375rem] lg:text-[3rem] sm:!text-[2rem] sm:leading-[2.2rem] tracking-[0.96px] text-center'>
            {t('hero.title.firstPart')}
            <br />
            {t('hero.title.secondPart')}
          </h1>
        </div>

        <HeroForm />
      </motion.div>

      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2, delay: 4 }}
          style={{ willChange }}
        >
          <ProgressBar progress={minmax((sold / total) * 100, 2, 100)}>
            <div className='flex flex-row gap-2 items-center text-[#512248] whitespace-nowrap'>
              <span className='font-bold text-[2rem] sm:text-[.8rem] '>
                {formattedSold} $PARCHA
              </span>

              <span className='font-medium text-center text-base sm:text-[.8rem] leading-5'>
                {t('hero.sold')}
              </span>
            </div>
          </ProgressBar>
        </motion.div>
      </AnimatePresence>
    </div>
  )
}
