import clsx from 'clsx';
import { ReactNode } from 'react';
import { formatNumber } from 'utils/number';

interface ChartLabelProps {
  label: ReactNode;
  value: number;
  color: string;
  border?: boolean;
}

export function ChartLabel({ label, value, color, border }: ChartLabelProps) {
  const formattedValue = formatNumber(value);

  return (
    <div className='flex gap-3'>
      <div
        className={clsx(
          'min-w-[0.75rem] h-3 my-1.5',
          border && 'border border-white border-opacity-50'
        )}
        style={{ background: color }}
      />
      <div className='flex flex-col font-bold text-lg leading-6'>
        <span className='whitespace-nowrap lg:whitespace-normal'>{label}</span>
        <span className='font-normal text-sm text-white text-opacity-70'>
          {formattedValue}
        </span>
      </div>
    </div>
  );
}
