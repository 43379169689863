import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { wagmiConfig } from 'configurations/wagmi';
import { WagmiProvider } from 'wagmi';
import { LandingView } from './screens/Landing';
import { useLayoutEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { api } from 'services/api';

function App() {
  useLayoutEffect(() => {
    api.getCurrentRound();
    api.getCurrentBonus();
    api.getCurrentReferralProgram();
    api.getProgress();
    api.getSettings();
    api.getBanner();
  }, []);

  const queryClient = new QueryClient();

  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider modalSize='compact'>
          <LandingView />
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
