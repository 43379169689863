import { Root as AccordionRoot } from "@radix-ui/react-accordion";
import { RoadmapAccordion } from "./RoadmapAccordion";
import { roadmapMockData } from "./data";
import tokenomicsBg from "assets/backgrounds/tokenomicsBg.webp";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export function Roadmap() {
  const roadmapData = roadmapMockData();
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });
  const [isScrolled, setIsScrolled] = useState(false);
  useMotionValueEvent(scrollYProgress, "change", (value) =>
    value > 0.7 ? setIsScrolled(true) : setIsScrolled(false)
  );
  const { t } = useTranslation();

  return (
    <div
      className='px-[4.25rem] lg:px-[2rem] sm:!pr-0 pb-[3.5rem] mb-32 text-white relative'
      id='roadmap'
    >
      <img
        src={tokenomicsBg}
        alt=''
        className='absolute w-[1420px] h-[888px] object-cover md:object-contain z-[-1] translate translate-x-[-26.35%] md:translate-x-[-36.35%] md:translate-y-[-30.3%] translate-y-[-20.3%]'
      />
      <div className='flex h-full'>
        <div className='flex flex-col relative'>
          <h2 className='-mb-2.5 font-medium text-[2rem] leading-snug lg:absolute lg:-translate-y-10 lg:translate-x-2'>
            {t("roadmap.title")}
          </h2>
          <div
            ref={targetRef}
            className='h-full grow lg:absolute lg:translate-y-5 lg:-left-20'
          >
            <div className='h-1/2 lg:pt-8 lg:pb-8'>
              <div className='sticky top-0 lg:top-8'>
                <motion.h3
                  initial={{ scale: 1, opacity: 1 }}
                  animate={{
                    scale: isScrolled ? 0.5 : 1,
                    opacity: isScrolled ? 0.3 : 1,
                  }}
                  className='font-medium text-[7.5rem] tracking-[2.4px] lg:!-rotate-90 lg:text-[3rem] '
                >
                  2024
                </motion.h3>
              </div>
            </div>
            <div className='h-1/2 lg:pt-8 lg:pb-8'>
              <motion.h3
                initial={{ scale: 0.5, opacity: 0.3 }}
                animate={{
                  scale: isScrolled ? 1 : 0.5,
                  opacity: isScrolled ? 1 : 0.3,
                }}
                className='sticky top-0 lg:top-8 font-medium text-[7.5rem] tracking-[2.4px] lg:!-rotate-90 lg:text-[3rem]'
              >
                2025
              </motion.h3>
            </div>
          </div>
        </div>
        <div className='grow max-w-[752px] pl-[100px] ml-auto pt-7'>
          <AccordionRoot
            type='single'
            collapsible
            className='flex flex-col gap-8 divide-y divide-white divide-opacity-50'
          >
            {roadmapData.map(({ sectionName, accordions }, i) => (
              <section
                className='pt-8 first:pt-0'
                key={`roadmap-${i}`}
              >
                <div className='flex justify-center items-center w-max sm:w-fit mb-4 px-4 py-2 border border-white border-opacity-50 rounded-full bg-white bg-opacity-10 font-medium text-sm leading-none tracking-[0.28px] max-w-[550px]'>
                  {sectionName}
                </div>
                <div className='flex flex-col gap-6'>
                  {accordions.map(({ title, content }, j) => (
                    <RoadmapAccordion
                      key={`roadmap-${i}-${j}`}
                      title={title}
                      value={`roadmap-${i}-${j}`}
                    >
                      {content}
                    </RoadmapAccordion>
                  ))}
                </div>
              </section>
            ))}
          </AccordionRoot>
        </div>
      </div>
    </div>
  );
}
