import * as Popover from '@radix-ui/react-popover'
import { motion } from 'framer-motion'
import { countryFlags } from '../../../../constants/location'
import './styles.css'
import { Tick } from 'assets/Tick'

type Props = {
  isOpenedOptions: boolean
  setOpenedOptions: (val: boolean) => void
  locations: { [key: string]: string }
  setLocation: (method: string) => void
  location: string
  type?: 'location'
}

export const LocationSelector: React.FC<Props> = ({
  isOpenedOptions,
  setOpenedOptions,
  location,
  setLocation,
  locations,
  type
}) => {
  return (
    <Popover.Root
      open={isOpenedOptions}
      onOpenChange={(val) => {
        setOpenedOptions(val)
      }}
      modal={false}
    >
      <Popover.Trigger asChild>
        <button type='button' className='button-connect location-button'>
          {countryFlags[location] ? (
            <div className='flex items-center gap-2'>
              <div className='flex sm:w-5 sm:h-5 w-7 h-7'>
                <img src={countryFlags[location]} alt='flag' className='object-cover' />
              </div>
            </div>
          ) : (
            <span>{location}</span>
          )}
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.PopoverContent side='bottom' avoidCollisions={false} asChild>
          <div
            id='select-asset-menu'
            className='bg-white p-4 max-w-[230px] max-h-80 overflow-auto rounded-[20px]'
          >
            <motion.ul
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className='flex flex-col justify-between gap-2'
            >
              {Object.entries(locations).map(([key, name], i) => {
                return (
                  <li
                    className='w-full selector text-[#49122d] flex items-center gap-2 hover:bg-btn-primary py-2 px-3 rounded-[8px] min-w-20 transition-all hover:cursor-pointer'
                    key={key}
                    onClick={(e) => setLocation(key)}
                  >
                    <div className='flex w-1/5'>
                      <img className='w-7 h-7' src={countryFlags[key]} alt='flag' />
                    </div>
                    <span className='w-4/5'>{name}</span>
                    {key === location && (
                      <Tick width={12} height={12} color='#000000' />
                    )}
                  </li>
                )
              })}
            </motion.ul>
          </div>
        </Popover.PopoverContent>
      </Popover.Portal>
    </Popover.Root>
  )
}
