import { FormProvider, useForm } from 'react-hook-form';
import {
  PaymentMethod,
  paymentMethods,
} from 'screens/Landing/constants/methods';
import { Dialog } from 'ui-kit/Dialog';
import { ErrorResult } from './Error';
import { SelectAsset } from './SelectAsset';
import { SendDeposit } from './SendDeposit';
import { SuccessResult } from './Success';
import { PaymentFormType } from './scheme';
import { RefCode } from './RefCode';
import { useUserStore } from 'store/user';
import { useLayoutEffect } from 'react';
import { api } from 'services/api';

interface PaymentDialogProps {
  paymentInUSD: number;
  open: boolean;
  onOpenChange: (val: boolean) => void;
  paymentMethod: PaymentMethod;
}

export function PaymentDialog({
  paymentInUSD,
  open,
  onOpenChange,
  paymentMethod,
}: PaymentDialogProps) {
  const [canEnterRefCode] = useUserStore((state) => [state.canEnterRefCode]);
  const form = useForm<PaymentFormType>({
    defaultValues: {
      step: canEnterRefCode ? 'enterRefCode' : 'selectAsset',
      paymentMethod: paymentMethods[0],
      paymentStatus: 'WAITING_PAYMENT',
      payAmount: 0,
      payAddress: '',
    },
  });

  useLayoutEffect(() => {
    if (!open) {
      form.setValue('step', canEnterRefCode ? 'enterRefCode' : 'selectAsset');
    }
  }, [canEnterRefCode, open]);

  const step = form.watch('step');

  const handleOpenChange = (val: boolean) => {
    onOpenChange(val);

    if (!val) {
      api.isRefAvailable();

      form.reset();
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={handleOpenChange}
      disableInteractOutside={step === 'sendDeposit'}
    >
      <FormProvider {...form}>
        <div className='flex flex-col w-[517px] sm:w-[320px] gap-6 px-8 pt-[3.125rem] pb-8'>
          {step === 'enterRefCode' && <RefCode />}
          {step === 'selectAsset' && (
            <SelectAsset
              paymentInUSD={paymentInUSD}
              preselectedMethod={paymentMethod}
            />
          )}
          {step === 'sendDeposit' && (
            <SendDeposit paymentInUSD={paymentInUSD} />
          )}
          {step === 'success' && <SuccessResult />}
          {step === 'error' && <ErrorResult />}
        </div>
      </FormProvider>
    </Dialog>
  );
}
