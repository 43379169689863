import { useLayoutEffect } from 'react';
import DonutChart from 'react-donut-chart';
import { Logo } from 'ui-kit/icons/Logo';
import { path } from 'utils/arc';
import { ChartLabel } from './ChartLabel';
import { tokenomicsMockData } from './dataset';
import './styles.css';
import { useWindowSize } from 'hooks/useWindowSize';
import { useTranslation } from 'react-i18next';

const INNER_RADIUS = 0.4;
const OUTER_RADIUS = 0.85;

interface ArcData {
  angels: number[];
  angle: number;
}

export function   Tokenomics() {
  const mockData = tokenomicsMockData()
  const tokenomicsSum = mockData.reduce((acc, item) => acc + item.value, 0);
  const { width } = useWindowSize();
  const pieSize = width > 640 ? 392 : 200;
  const { t } = useTranslation()

  const handleMouseEnter = (
    arc: SVGPathElement,
    activeAngle: number,
    arcData: ArcData,
    i: number
  ) => {
    arc.setAttribute(
      'd',
      path(
        activeAngle,
        arcData.angels[i] - activeAngle,
        pieSize,
        INNER_RADIUS,
        OUTER_RADIUS + 0.1
      )
    );
  };

  const handleMouseLeave = (
    arc: SVGPathElement,
    activeAngle: number,
    arcData: ArcData,
    i: number
  ) => {
    arc.setAttribute(
      'd',
      path(
        activeAngle,
        arcData.angels[i] - activeAngle,
        pieSize,
        INNER_RADIUS,
        OUTER_RADIUS
      )
    );
  };

  useLayoutEffect(() => {
    const arcs = document.querySelectorAll(
      '.donutchart-arcs-path'
    ) as NodeListOf<SVGPathElement>;

    const arcData = mockData.reduce<ArcData>(
      (acc, item, i) => {
        const { value } = item;
        const angel = acc.angle + (value / tokenomicsSum) * 360;
        return {
          angels: [...acc.angels, angel],
          angle: angel,
        };
      },
      { angels: [], angle: 0 }
    );

    arcs.forEach((arc, i) => {
      const { value } = mockData[i];
      const activeAngle =
        Number.isNaN(value / tokenomicsSum) || tokenomicsSum / value === 1
          ? 359.99
          : (value / tokenomicsSum) * 360;
      arc.addEventListener(
        'mouseenter',
        handleMouseEnter.bind(null, arc, activeAngle, arcData, i)
      );
      arc.addEventListener(
        'mouseleave',
        handleMouseLeave.bind(null, arc, activeAngle, arcData, i)
      );
    });

    return () => {
      arcs.forEach((arc) => {
        arc.removeEventListener('mouseenter', handleMouseEnter.bind);
        arc.removeEventListener('mouseleave', handleMouseLeave.bind);
      });
    };
  }, [tokenomicsSum, pieSize]);

  return (
    <div className='px-[4.75rem] lg:px-[1rem] sm:!px-[0rem] py-[6.25rem] sm:pt-[2rem] text-[#512248]'>
      <h2
        className='flex items-center pl-4 gap-2 font-medium text-[3.375rem] lg:text-[2rem] leading-none tracking-[1px]'
        id='tokenomics'
      >
        <Logo width={55} height={55} />
        $PARCHA {t('tokenomics')}
      </h2>
      <div className='relative z-[1] flex lg:flex-col gap-16 lg:gap-2 pt-[3.375rem] pl-4 pb-16 divide-x divide-white divide-opacity-[0.42] backdrop-blur-[15px]'>
        <div className='flex flex-col lg:flex-row lg:items-center lg:gap-4 font-normal text-base leading-6'>
          <span className='font-bold text-[1.75rem] leading-10'>ERC-20</span>
          <span className='uppercase'>Ethereum {t('network')}</span>
        </div>
        <div className='flex flex-col lg:flex-row lg:items-center lg:gap-4 pl-16 lg:pl-0 font-normal text-base leading-6'>
          <span className='font-bold text-[1.75rem] leading-10'>
            11 {t('december')} 2024
          </span>
          <span className='uppercase'>{t('tokenomics.presaleEnd')}</span>
        </div>
        <div className='flex flex-col lg:flex-row lg:items-center lg:gap-4 pl-16 lg:pl-0 font-normal text-base leading-6'>
          <span className='font-bold text-[1.75rem] leading-10'>
            2.5 {t('billion')}
          </span>
          <span className='uppercase'>{t('tokenomics.totalSupply')}</span>
        </div>
      </div>
      <div className='flex items-center xl-1440:flex-col px-[3rem] sm:py-[2rem] lg:gap-4 lg:px-2 py-[5rem] rounded-[1.25rem] bg-tokenomics text-white'>
        <div className='grid grid-cols-2 grid-rows-3 grid-flow-col gap-x-[5.5rem] gap-y-[3.75rem] lg:grid-cols-1 lg:grid-rows-5 lg:gap-y-[1rem]'>
          {mockData.map((item, i) => (
            <ChartLabel
              key={i}
              label={item.label + ` ${(item.value / tokenomicsSum) * 100}%`}
              value={item.value}
              color={item.color}
              border={item.border}
            />
          ))}
        </div>

        <DonutChart
          data={mockData.map((item) => ({
            label: item.label,
            value: item.value,
          }))}
          innerRadius={INNER_RADIUS}
          outerRadius={OUTER_RADIUS}
          strokeColor='none'
          selectedOffset={0}
          clickToggle={false}
          colors={mockData.map((item) => item.color)}
          width={pieSize}
          height={pieSize}
          legend={false}
        />
      </div>
    </div>
  );
}
