import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@radix-ui/react-accordion";
import { ReactNode } from "react";
import { AccordionTriggerIcon } from "ui-kit/icons/AccordionTriggerIcon";

interface FAQAccordionProps {
  value: string;
  title: string;
  children: ReactNode;
}

export function FAQAccordion({ value, title, children }: FAQAccordionProps) {
  return (
    <AccordionItem
      value={value}
      className="text-left rounded-2xl bg-white accordion-trigger"
    >
      <AccordionTrigger className="flex text-left items-center w-full gap-4 p-6 sm:p-2 font-medium text-lg leading-snug sm:text-sm">
        <AccordionTriggerIcon style={{ minWidth: '24px' }} />
        {title}
      </AccordionTrigger>
      <AccordionContent className="pl-16 pr-8 sm:pl-12 sm:pr-2 font-normal text-base leading-snug text-[#9A8C97] accordion-animation sm:text-sm">
        <div className="pb-6">{children}</div>
      </AccordionContent>
    </AccordionItem>
  );
}
