export function Cloud2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="592"
      height="221"
      viewBox="0 0 592 221"
      fill="none"
    >
      <path
        d="M591.974 25.0559C577.693 9.63485 557.328 0 534.719 0C509.655 0 487.359 11.8341 473.052 30.2399C465.35 24.7156 455.951 21.4429 445.769 21.4429C424.386 21.4429 406.372 35.7904 400.68 55.3744C395.302 53.2275 389.48 51.9969 383.344 51.9969C357.524 51.9969 336.585 72.9947 336.585 98.8884C336.585 98.9146 336.585 98.9408 336.585 98.9931C335.958 98.967 335.358 98.8884 334.731 98.8884C318.127 98.8884 303.715 108.209 296.352 121.928C294.681 121.666 292.958 121.483 291.209 121.483C283.037 121.483 275.544 124.442 269.748 129.338C261.446 118.656 248.522 111.744 233.98 111.744C212.363 111.744 194.296 126.929 189.753 147.246C187.038 146.304 184.14 145.78 181.112 145.78C170.198 145.78 160.826 152.43 156.805 161.882C155.473 161.62 154.116 161.489 152.732 161.489C144.116 161.489 136.702 166.568 133.255 173.899C129.444 166.542 121.794 161.489 112.943 161.489C102.109 161.489 93.049 169.055 90.6732 179.214C87.1486 174.972 81.8748 172.276 75.9483 172.276C65.6095 172.276 57.2027 180.497 56.8111 190.786C50.8062 185.733 43.0522 182.67 34.5932 182.67C15.4821 182.67 0 198.196 0 217.361C0 218.591 0.0782636 219.796 0.182696 221H592V25.0298L591.974 25.0559Z"
        fill="url(#paint0_linear_1_227)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_227"
          x1="297.684"
          y1="-5.83853"
          x2="295.058"
          y2="339.786"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBC5B7" />
          <stop offset="1" stopColor="#FFD6DD" />
        </linearGradient>
      </defs>
    </svg>
  );
}