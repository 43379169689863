import { ReactNode, useLayoutEffect, useRef } from "react";
import { RightArrow } from "ui-kit/icons/RightArrow";
import "./styles.css";
import { minmax } from 'utils/number';

interface ProgressBarProps {
  /**
   * Progress in percent
   */
  progress: number;
  /**
   * Tooltip content which placed above the current progress
   */
  children?: ReactNode;
}

export function ProgressBar({ progress, children }: ProgressBarProps) {
  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleWindowResize = () => {
    if (!tooltipRef.current) return;

    const tooltip = tooltipRef.current;
    const tooltipBoundingRect = tooltip.getBoundingClientRect();
    const tooltipWidth = tooltipBoundingRect.width;
    const tooltipLeft = tooltipBoundingRect.left;
    const tooltipRight = tooltipBoundingRect.right;
    const windowWidth = window.innerWidth;

    if (tooltipRight > windowWidth) {
      tooltip.style.transform = `translateX(-${tooltipWidth}px)`;
    }

    if (tooltipLeft < 0) {
      tooltip.style.transform = `translateX(0)`;
    }
  };

  useLayoutEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div className="relative flex w-full h-2 bg-white z-[10]">
      <div
        className="progress-gradient rounded-r-full"
        style={{ width: `${Math.min(progress, 100)}%` }}
      />
      <div
        className="absolute top-1/2 -translate-x-full -translate-y-1/2 min-w-6 h-6 rounded-full bg-[#FEABCB]"
        style={{
          left: `${Math.min(progress, 100)}%`,
        }}
      >
        <RightArrow />
      </div>
      <div
        className="absolute bottom-4 -translate-x-[calc(50%+1rem)]"
        style={{ left: `${minmax(isNaN(progress) ? 0 : progress,2, 98)}%` }}
        ref={tooltipRef}
      >
        {children}
      </div>
    </div>
  );
}
