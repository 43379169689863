import { SVGAttributes } from "react";

export function Clear(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
    >
      <circle cx="10" cy="10.5" r="10" fill="#CF6B89" />
      <path
        d="M13.75 6.75L6.25 14.25"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.25 6.75L13.75 14.25"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
} 