import { useTranslation } from 'react-i18next';
import { FeatureCard } from './FeatureCard';
import './styles.css';

export function Features() {
  const { t } = useTranslation()

  return (
    <div
      className='flex flex-col mx-auto mt-[12.5rem] sm:mt-[3rem]'
      id='features'
    >
      <h2 className='font-medium text-[7.5rem] leading-none text-center tracking-[2%] text-[#b8ada8] sm:text-[3rem]'>
        {t('features.title')}
      </h2>
      <div
        className='flex flex-col -mt-[12vh] lg:mt-0 gap-[40vh] items-center'
        style={{ minHeight: '250vh' }}
      >
        <FeatureCard
          title={t('features.reachAndUsing')}
          description={
            <ul className='pl-4 list-disc'>
              <li>{t('features.reachAndUsing.description')}</li>
            </ul>
          }
          tags={[t('features.tags.globalReach'), t('features.tags.easeUse')]}
          className='bg-card1 text-black transform translate-y-[-50%] rotate-[-0.752deg]'
          classNames={{ tag: 'bg-black bg-opacity-15' }}
        />
        <FeatureCard
          title={t('features.blockchainVerification')}
          description={
            <ul className='pl-4 list-disc'>
              <li>{t('features.blockchainVerification.description')}</li>
            </ul>
          }
          tags={[t('features.tags.trustworthyTransactions')]}
          className='bg-card2 text-white transform translate-x-[2.125rem] translate-y-[calc(-50%+1.25rem)] rotate-[7.435deg] lg:rotate-[1.435deg]'
        />
        <FeatureCard
          title={t('features.recordKeeping')}
          description={
            <ul className='pl-4 list-disc'>
              <li>{t('features.recordKeeping.description')}</li>
            </ul>
          }
          tags={[t('features.tags.immutable'), t('features.tags.blockchain')]}
          className='bg-card3 text-white transform translate-x-[-2.375rem] translate-y-[calc(-50%+2.375rem)] rotate-[-4.05deg] lg:rotate-[-1.435deg]'
        />
        <FeatureCard
          title={t('features.secureReviews')}
          description={
            <ul className='pl-4 list-disc'>
              <li>{t('features.secureReviews.description')}</li>
            </ul>
          }
          tags={[t('features.tags.secure'), t('features.tags.protected')]}
          className='bg-card1 text-black'
          classNames={{ tag: 'bg-black bg-opacity-15' }}
          style={{
            transform: 'translate(-6%, -48%) rotateZ(-1.752deg)',
          }}
        />
        <FeatureCard
          title={t('features.customOffers')}
          description={
            <ul className='pl-4 list-disc'>
              <li>{t('features.customOffers.description')}</li>
            </ul>
          }
          tags={[t('features.tags.flexibility'), t('features.tags.proposalSystem')]}
          className='bg-card2 text-white transform translate-x-[2.125rem] translate-y-[calc(-50%+1.25rem)] rotate-[2.435deg] lg:rotate-[.135deg]'
        />
        <FeatureCard
          title={t('features.digitalSigning')}
          description={
            <ul className='pl-4 list-disc'>
              <li>{t('features.digitalSigning.description')}</li>
            </ul>
          }
          tags={[t('features.tags.efficient'), t('features.tags.paperless')]}
          className='bg-card3 text-white transform translate-x-[-2.375rem] translate-y-[calc(-50%+2.375rem)] rotate-[-3deg] lg:rotate-[-1.5deg]'
        />
        <FeatureCard
          title={t('features.controlPanel')}
          description={
            <>
              <p className='mb-1'>
                {t('features.controlPanel.description.firstPart')}
              </p>
              <ul className='pl-4 list-disc'>
                <li>
                  {t('features.controlPanel.description.secondPart')}
                </li>
                <li>
                  {t('features.controlPanel.description.thirdPart')}
                </li>
              </ul>
            </>
          }
          tags={[t('features.tags.controlPanel'), t('features.tags.userFriendlySystem')]}
          className='bg-card1 text-black'
          classNames={{ tag: 'bg-black bg-opacity-15' }}
          style={{
            transform: 'translate(0, calc(-50% + 1.25rem)) rotateZ(-2deg)',
          }}
        />
      </div>
    </div>
  );
}
