export function Cloud1() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="552"
      height="276"
      viewBox="0 0 552 276"
      fill="none"
    >
      <path
        d="M538.515 268.868C537.237 268.868 535.985 269.024 534.811 269.31C535.541 267.02 535.906 264.573 535.906 262.048C535.906 248.564 524.951 237.631 511.44 237.631C504.215 237.631 497.72 240.781 493.233 245.779C489.79 238.933 482.722 234.221 474.531 234.221C472.001 234.221 469.602 234.69 467.385 235.497C467.411 235.08 467.437 234.638 467.437 234.221C467.437 226.23 460.942 219.748 452.934 219.748C450.117 219.748 447.509 220.555 445.266 221.935C442.188 205.64 427.868 193.302 410.652 193.302C407.262 193.302 404.001 193.796 400.923 194.681C398.419 183.072 388.664 174.143 376.587 172.868C376.692 171.618 376.77 170.369 376.77 169.119C376.77 143.505 355.955 122.707 330.263 122.707C319.49 122.707 309.604 126.403 301.727 132.547C295.91 119.688 282.973 110.733 267.923 110.733C258.506 110.733 249.951 114.247 243.404 120.026C241.03 118.829 238.526 117.892 235.892 117.267C235.892 116.954 235.944 116.616 235.944 116.304C235.944 91.705 215.964 71.7657 191.315 71.7657C179.838 71.7657 169.378 76.1388 161.475 83.2451C151.302 65.8308 132.392 54.1171 110.742 54.1171C106.282 54.1171 101.952 54.6377 97.8047 55.5748C94.8833 24.3904 68.5909 0 36.6123 0C21.0403 0 6.82473 5.77871 -4 15.3058V276H552C549.079 271.705 544.123 268.894 538.515 268.894V268.868Z"
        fill="url(#paint0_linear_1_226)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_226"
          x1="274.756"
          y1="44.9024"
          x2="272.159"
          y2="388.503"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC8BA" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}