import ErrorIcon from 'assets/error.webp';
import { useFormContext } from 'react-hook-form';
import { Button } from 'ui-kit/Button';
import { PaymentFormType } from './scheme';
import { useTranslation } from 'react-i18next';

export function ErrorResult() {
  const form = useFormContext<PaymentFormType>();
  const { t } = useTranslation()

  if (!form)
    throw new Error('This component must be used within a <FormProvider />');

  const handleRestart = () => {
    form.reset();
    form.setValue('step', 'selectAsset');
  };

  return (
    <>
      <div className='flex justify-center'>
        <img src={ErrorIcon} className='w-[69px] h-[79px]' alt='Success icon' />
      </div>
      <div className='flex flex-col items-center gap-2'>
        <h2 className='font-medium text-2xl leading-snug text-[#512248] text-center'>
          {t('errorResult.title')}
        </h2>
        <p className='mt-2 mb-4 text-center font-medium text-sm text-[#978D95]'>
          {t('errorResult.description')}
        </p>
      </div>
      <Button
        className='h-[3.375rem]'
        variant='primary'
        onClick={handleRestart}
      >
        {t('errorResult.sendTransfer')}
      </Button>
    </>
  );
}
