import { auditLink } from '../Header/components/Navigation';
import i18n from 'i18n';

export const faqData = () => [
  {
    title: i18n.t('faq.whatIsParadiseChain'),
    content: (
      <>
        {i18n.t('faq.whatIsParadiseChain.answer')}
      </>
    ),
  },
  {
    title: i18n.t('faq.audited'),
    content: (
      <>
        {i18n.t('faq.audited.answer.firstPart')}{' '}
        <a
          className='text-[#4959ff] hover:underline visited:text-[#8a24ff]'
          href={auditLink}
          target='_blank'
          rel='noreferrer'
        >
          {i18n.t('faq.audited.answer.secondPart')}
        </a>
        .
      </>
    ),
  },
  {
    title: i18n.t('faq.contractAddress'),
    content: (
      <>
        {i18n.t('faq.contractAddress.answer')}{' '}
        <span className='break-words'>
          0xF4194164E26e114EF13cCb9A363F338F83c207A6
        </span>
      </>
    ),
  },
  {
    title: i18n.t('faq.presaleStages'),
    content: <>{i18n.t('faq.presaleStages.answer')}</>,
  },
  {
    title: i18n.t('faq.ableReceive'),
    content: (
      <>{i18n.t('faq.ableReceive.answer')}</>
    ),
  },
  {
    title: i18n.t('faq.buyTokens'),
    content: (
      <>
        {i18n.t('faq.buyTokens.answer')}{' '}
        <a
          className='text-[#4959ff] hover:underline visited:text-[#8a24ff]'
          href='https://paradisechain.net/'
        >
          https://paradisechain.net
        </a>
        .
        <br />
        <br />
        <p className='underline'>
          {i18n.t('faq.buyTokens.warning')}
        </p>
      </>
    ),
  },
];
